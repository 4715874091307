import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  List,
  Label,
  Card,
  Button,
  Icon
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './XaaSAcademyPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const PageHeading = ({ mobile }) => (
  <div className="xaasAcademyOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        <Header
          as='h2'
          content={
          <React.Fragment>
            <span style={{ fontFamily: 'Lora, serif' }}>XaaS Academy<span style={{color: "rgb(0, 195, 255)" }}>.</span></span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        />
        <Header
          as='h2'
          content={
          <React.Fragment>
            Build an <span style={{color: "rgb(0, 195, 255)", fontWeight: 'bold' }}>X-as-a-Service</span> Business with Us.
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        />

        {/* <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
          style={{
            marginTop: '1em'
          }}
        >
          Learn More About Debt
          <Icon name='right chevron' />
        </Button> */}
      </Container>
    </Segment>
  </div>
);

const XaaSAcademyPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <PageHeading {...props} />

      <Segment style={{ padding: '0em 0em 5em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Divider hidden />

          <Grid.Row reversed="computer">
            <Grid.Column width={8} textAlign="center">
              <Image src={'media/illustrations/iot_c.jpg'} centered size="large" />
              {/* <p style={{ marginTop: '6px' }}><i>IoT t.</i></p> */}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '3em' }}>
                <Header.Content>Anything as a Service</Header.Content>
                <Header.Subheader>(And yes, even including some of the most sophisticated hardware)</Header.Subheader>
              </Header>
              <p style={{ fontSize: '1.75em' }}>
                Many hardware companies are beginning to realize that <strong>a one-time transactional model is a missed opportunity</strong>, and that limited contact with end customers is a major obstacle to growth.
              </p>
              <p className="hugeText">
                Providing ongoing services to your customer allows companies to maintain a direct and ongoing link with end customers, generating deep customer insights and leading to more value for all parties involved.
              </p>
              <p className="hugeText">
                <strong>IoT technologies have made this service-based business model possible for most hardware</strong>, which is allowing <a href="https://www.tcs.com/perspectives/articles/what-happens-when-you-turn-your-products-into-services" target="_blank" rel="noopener noreferrer">exciting new data-driven business models and financing mechanisms to form</a>.
              </p>

              <a href="https://app.perlstreet.com/register">

              <Button 
                // as={Link} to="/signup" 
                color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round"
              >
                Get Started
                <Icon name='right chevron' />
              </Button>
              </a>
            </Grid.Column>
          </Grid.Row>

          {/* <Grid.Row>
            <Grid.Column>
              <Divider
                as='h4'
                className='header'
                horizontal
                style={{ margin: '1em 0em 4em 0', textTransform: 'uppercase' }}
              >
                <p style={{ color: "#4183c4" }}>XAAS EXAMPLES IN THE MARKET</p>
              </Divider>
            </Grid.Column>
          </Grid.Row> */}

          {/* <Container>
            <Card.Group centered>
              <Card>
                <Card.Content>
                  <a href="https://joinborrow.com/" target="_blank" rel="noopener noreferrer">
                    <Image src="/logos/xaas-examples/BorrowLogo.png" floated="right" size="mini" />
                  </a>
                  <Card.Header><a href="https://joinborrow.com/" target="_blank" rel="noopener noreferrer">Borrow</a></Card.Header>
                  <Card.Meta>Electric Vehicles as a Service</Card.Meta>
                  <Card.Description>
                    Short-term electric vehicle subscriptions as an alternative to full ownership.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <a href="https://www.apple.com/shop/iphone/iphone-upgrade-program" target="_blank" rel="noopener noreferrer">
                    <Image src="/logos/xaas-examples/AppleLogo.png" floated="right" size="mini" />
                  </a>
                  <Card.Header>
                    <a href="https://www.apple.com/shop/iphone/iphone-upgrade-program" target="_blank" rel="noopener noreferrer">
                      Apple, Inc.
                    </a>
                  </Card.Header>
                  <Card.Meta>iPhone Upgrades as a Service</Card.Meta>
                  <Card.Description>
                    Get a brand new iPhone and AppleCare every year with a low monthly payment.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <a href="http://ener.co/" target="_blank" rel="noopener noreferrer">
                    <Image src="/logos/xaas-examples/EnerCoLogo.png" floated="right" size="mini" />
                  </a>
                  <Card.Header>
                    <a href="http://ener.co/" target="_blank" rel="noopener noreferrer">
                      Ener.co
                    </a>
                  </Card.Header>
                  <Card.Meta>Cooling as a Service</Card.Meta>
                  <Card.Description>
                    High efficiency HVAC equipment with no up-front costs to the customer.
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
            <Segment textAlign="center" basic>
              <p style={{ fontSize: '1.33em' }}>
                <i>... and so much more.</i><br/><br/>
                Not listed above? Absolutely no problem.<br/>
                Our solution works for most capital-intensive businesses.
              </p>
            </Segment>
          </Container> */}

        </Grid>
      </Segment>

      <div className="caasQuoteOverlay">
        <Segment style={{ padding: '5em 0' }} vertical>
          <Container text 
            textAlign="center"
            style={{
              paddingTop: '2em',
              paddingBottom: '2em'
            }}
          >
            <Header
              inverted
              as='h2'
              content={
              <React.Fragment>
                <span style={{ fontFamily: 'Lora, serif' }}>"People don’t want to buy a quarter-inch drill, <span style={{color: "#40d4e4" }}>they want a quarter-inch hole</span>."</span>
              </React.Fragment>}
              style={{
                fontSize: '3em',
                fontWeight: '700',
                marginTop: '0.5em',
                marginBottom: '0em'
              }}
            />
            <Header
              inverted
              as='h2'
              content={
              <React.Fragment>
                — Theodore Levitt
              </React.Fragment>}
              style={{
                fontSize: '1.7em',
                fontWeight: 'normal',
                marginTop: '1em',
                marginBottom: '1em'
              }}
            />
          </Container>
        </Segment>
      </div>

      <Segment style={{ padding: '3em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Grid.Row>
            <Grid.Column width={7}>
              <Header as='h3' style={{ fontSize: '2.5em' }}>
                <Header.Content>
                  Why Go <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>XaaS</span>?
                </Header.Content>
              </Header>

              {/* <p className="hugeText">By properly structuring your deal with Perl Street, you are lowering the perceived risk by lenders. <i>This enables more options for your company:</i></p> */}
              
              <Header as='h3' style={{ fontSize: '1.5em', fontWeight: 'normal' }}>
                <i>A service-based business model for your company can allow you to <span style={{ textDecoration: 'underline' }}>build a more naturally defensible and scalable business</span>:</i>
              </Header>

              <List as='ul' size="huge">
                <List.Item as='li'>Lock your customers into long-term contracts</List.Item>
                <List.Item as='li'>Increase conversion rates and lower your churn</List.Item>
                <List.Item as='li'>Reduce upfront costs for your customers</List.Item>
                <List.Item as='li'>Generate additional revenue opportunities</List.Item>
                <List.Item as='li'>Achieve software-like margins with your hardware</List.Item>
                <List.Item as='li'>Unlock natural financing opportunities</List.Item>
                <List.Item as='li'>Ultimately increase your company’s valuation</List.Item>
              </List>

            </Grid.Column>
            <Grid.Column width={9} textAlign="center">
              <Image src={'media/illustrations/XaaS-Success.jpg'} centered style={{ marginTop: '3.5em' }} />
            </Grid.Column>
          </Grid.Row>

          <Container style={{ display: 'block' }}>
            <Segment textAlign="center" basic>
              <p style={{ fontSize: '1.33em' }}>
                <i>... and guess what?</i><br/><br/>
                <strong><span style={{ textDecoration: 'underline' }}>Everybody can win</span> with a XaaS model.</strong><br/>
              </p>
            </Segment>
            <Card.Group centered>
              <Card>
                <Image src='/media/thumbnails/CompanyThumbnail.jpg' wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Your Company</Card.Header>
                  <Card.Description>
                    <List as='ul'>
                      <List.Item as='li'>Deploy assets without raising equity</List.Item>
                      <List.Item as='li'>Increase customer demand</List.Item>
                      <List.Item as='li'>More predictable revenue streams</List.Item>
                      <List.Item as='li'>Generate higher customer LTV</List.Item>
                    </List>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Image src='/media/thumbnails/CustomerThumbnail.jpg' wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Your Customers</Card.Header>
                  <Card.Description>
                    <List as='ul'>
                      <List.Item as='li'>Reduced (or zero) capital expenditures</List.Item>
                      <List.Item as='li'>Fully outsourced equipment servicing</List.Item>
                      <List.Item as='li'>No more equipment performance risk</List.Item>
                      <List.Item as='li'>Lower total operating expenses</List.Item>
                    </List>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Image src='/media/thumbnails/BankThumbnail.jpg' wrapped ui={false} />
                <Card.Content>
                  <Card.Header>Your Lenders & Investors</Card.Header>
                  <Card.Description>
                    <List as='ul'>
                      <List.Item as='li'>Invest in cash flow generating assets</List.Item>
                      <List.Item as='li'>Bankruptcy-remote structures</List.Item>
                      <List.Item as='li'>Securitization and recapitalization</List.Item>
                      <List.Item as='li'>Scalable financing opportunities</List.Item>
                    </List>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
          </Container>

          <Divider hidden section style={{ width: '100%' }} />

          <Grid.Row reversed="computer">
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '2.5em' }}>
                <Header.Content id="off-balance-sheet">
                  How Does the <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>XaaS Model</span> Work?
                </Header.Content>
              </Header>

              <p className="hugeText">
                Here's an example of how a XaaS structure might work:
              </p>

              <List as='ol' size="large">
                <List.Item as='li'>A project company is formed and operated by the technology provider</List.Item>
                <List.Item as='li'>The lender disburses the loan advances directly to the project company</List.Item>
                <List.Item as='li'>The project company buys hardware at cost from the technology provider</List.Item>
                <List.Item as='li'>The project company receives payments directly from the customers</List.Item>
                <List.Item as='li'>Products are delivered to customers through the project company</List.Item>
                <List.Item as='li'>Any remaining cash is distributed to the technology provider</List.Item>
              </List>

            </Grid.Column>
            <Grid.Column width={8} textAlign="center">
              <Image src={'media/illustrations/XaaS-Structure.jpg'} centered style={{ marginTop: '3.5em' }} />
              <Label>Above is an example of just <span style={{ textDecoration: 'underline' }}>one of many different XaaS model structures</span></Label>
            </Grid.Column>
          </Grid.Row>

          <Divider hidden />

          <Grid.Row>
            <Grid.Column width={7}>
              <Header as='h3' style={{ fontSize: '2.5em' }}>
                <Header.Content>
                  Join Our <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>XaaS Academy</span>.
                </Header.Content>
              </Header>

              <Header as='h3' style={{ fontSize: '1.5em', fontWeight: 'normal' }}>
                <i>Get access to all the tools you need to <span style={{ textDecoration: 'underline' }}>get your new XaaS business model up and running</span>:</i>
              </Header>

              <List as='ul' size="huge">
                <List.Item as='li'>Develop iron-clad XaaS service contracts</List.Item>
                <List.Item as='li'>Build an economic model for pricing your services</List.Item>
                <List.Item as='li'>Scenario sensitivity analysis and risk identification</List.Item>
                <List.Item as='li'>Prepare for financing and model new term sheets</List.Item>
                <List.Item as='li'>Deploy your pilot project and verify unit economics</List.Item>
              </List>

              <Divider hidden/>

              <a href="https://app.perlstreet.com/register">
              <Button 
                // as={Link} to="/signup" 
                color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round"
              >
                Get Started
                <Icon name='right chevron' />
              </Button>
              </a>

            </Grid.Column>
            <Grid.Column width={9} textAlign="center">
              <Image src={'media/illustrations/XaaS-Academy-Journey.jpg'} centered style={{ marginTop: '3.5em' }} size="large" />
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Segment>

      <Footer />
    </AppContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(XaaSAcademyPage);