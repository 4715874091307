import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  List,
  Label,
  Card
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './AboutPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const WhyDebtHeading = ({ mobile }) => (
  <div className="aboutPageOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        <Header
          as='h2'
          content={
          <React.Fragment>
            <span style={{ fontFamily: 'Lora, serif' }}>Who We Are<span style={{color: "rgb(0, 195, 255)" }}>.</span></span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        />
        <Header
          as='h2'
          content={
          <React.Fragment>
            Startup financing re-imagined.
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        />

        {/* <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
          style={{
            marginTop: '1em'
          }}
        >
          Learn More About Debt
          <Icon name='right chevron' />
        </Button> */}
      </Container>
    </Segment>
  </div>
)

WhyDebtHeading.propTypes = {
  mobile: PropTypes.bool,
}

const AboutPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <WhyDebtHeading {...props} />

      <Segment style={{ padding: '0em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Divider hidden />

          <Grid.Row reversed="computer">
            <Grid.Column width={8} textAlign="center">
              <Image src={'media/illustrations/nyc-hudson.jpg'} centered bordered size="large" />
              <p style={{ marginTop: '6px' }}><i>We believe in investing in the future of cities, hardware, and infrastructure.</i></p>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '3em' }}>
                About Perl Street
              </Header>
              <p style={{ fontSize: '1.75em' }}>
                Perl Street is a platform that was built around a startup problem rather than a capital market opportunity. We are laser-focused on solving the fundraising problem for hardware startups.
              </p>
              <p className="hugeText">
                We believe the current process of raising capital is a major distraction and cost to technology and hardware startups. Instead of focusing on delivering value to their customers and building innovative products and teams, startups spend much of their limited resources and time on sourcing capital, negotiating deal terms, drafting legal contracts and navigating complex and lengthy due diligence processes.
              </p>
              <p className="hugeText">
                <strong>Technology-accelerated creative deal formation</strong> can address these problems. With Perl Street, startups can structure off-balance sheet transactions and create a scalable borrowing facility for their hardware-driven business model.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Divider
                as='h4'
                className='header'
                horizontal
                style={{ margin: '1em 0em 4em 0', textTransform: 'uppercase' }}
              >
                <p style={{ color: "#4183c4" }}>MEET OUR TEAM AND OUR PARTNERS</p>
              </Divider>
            </Grid.Column>
          </Grid.Row>

          <Card.Group stackable itemsPerRow={4} style={{ paddingBottom: '1.2em' }}>
            <Card>
              <Image src='/media/portraits/Tooraj.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Tooraj Arvajeh</Card.Header>
                <Card.Meta>
                  CEO & Co-Founder
                </Card.Meta>
                <Card.Description>
                  Tooraj Arvajeh has 15+ years of global experience in creating technically feasible and financially attractive applications and market opportunities for clean and emerging technologies in built environment, energy and resources management and mobility sectors.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Dakota.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Dakota Wixom</Card.Header>
                <Card.Meta>
                CTO & Data Scientist
                </Card.Meta>
                <Card.Description>
                  Full-stack software engineering, financial modeling, and machine learning. Former quantitative analyst at an AI startup building signals for analyzing financial risk using graph theory. Online instructor of finance and analytics to thousands of students.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Mark.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Mark Paris</Card.Header>
                <Card.Meta>
                  Chairman & Co-Founder
                </Card.Meta>
                <Card.Description>
                  Mark Paris has more than 30 years of experience at the intersection of cities, technology and finance. Mark is a partner at Urban Us, an urban tech VC fund, and has been the Managing Director of URBAN-X, an urban tech accelerator program.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Peyman.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Peyman Faratin, Phd</Card.Header>
                <Card.Meta>
                  AI & Data Science Partner
                </Card.Meta>
                <Card.Description>
                  Chief Scientist at Two Sigma with 20+ years in AI. Entrepreneur, visiting scholar for the Courant Institute, and research scientist for the MIT CSAIL.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Arvind.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Arvind Vermani</Card.Header>
                <Card.Meta>
                  Capital Markets Partner
                </Card.Meta>
                <Card.Description>
                  23 years at HSBC in credit risk, structured credit, institutional wealth management, loan sourcing, and institutional sales.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Jace.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Jace Alexander Lotz</Card.Header>
                <Card.Meta>
                  CFO & Accounting Partner
                </Card.Meta>
                <Card.Description>
                  7 years in startup accounting, finance, audit, HR, and tax advisory with KPMG, Kruze Consulting, and tempCFO (Acquired by inDinero).
                </Card.Description>
              </Card.Content>
            </Card>
            {/* <Card>
              <Image src='/media/portraits/Anthony.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Anthony Millin</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  Lawyer at Shulman Rogers. Co-founder BioDatomics, Corrival. Wharton, George Washington University.
                </Card.Description>
              </Card.Content>
            </Card> */}
            <Card>
              <Image src='/media/portraits/Shaun.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Shaun Abrahamson</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  Managing Partner, Angel & LP (5 exits) 3 time operator (1 exit). The Economist. University Cape Town, MIT CADLab.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Shilpi.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Shilpi Kumar</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  Venture Partner at Urban Us, former technology commercialization and sales at Filament, Venture for America Fellow.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Stonly.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Stonly Baptiste</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  6 time founder (2 exits). CNNMoney 30 under 30, Guest contributor at TC. Teacher at University of Chicago.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/media/portraits/Anthony.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Anthony Millin</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  Lawyer at Shulman Rogers. Co-founder BioDatomics, Corrival. Wharton, George Washington University.
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>

        </Grid>
      </Segment>

      <Footer />
    </AppContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);