import React from 'react';
import { Header, Segment, Divider, Container } from 'semantic-ui-react';
import TextLinkButton from '../text-link-button/TextLinkButton.component';

import { connect } from 'react-redux';
import { setCurrentView } from '../../redux/view/view.actions';

class GettingStarted extends React.Component {
  render() {
    const { setCurrentView } = this.props;

    return (
      <Container>
        <Header as="h1">Welcome to Perl Street!</Header>
        <Segment.Group>
          <Segment>
            <Header>How to Get Started</Header>
          </Segment>
          <Segment>
            <Header>Step 1 - Add accounts</Header>
            <p>Add accounts by using the form on the <TextLinkButton onClick={() => setCurrentView("AccountList")}>Accounts</TextLinkButton> tab.</p>
            <p>You can add multiple accounts under both your HoldCo and SPE organizations.</p>
            <Divider hidden />
            <Header>That's it!</Header>
            <p>Any questions? Feel free to reach out to us at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a></p>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData))
});

export default connect(null, mapDispatchToProps)(GettingStarted);