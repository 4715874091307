import { createSelector } from 'reselect';

const selectAccounts = state => state.accounts;

export const selectCurrentAccounts = createSelector(
  [selectAccounts],
  (accounts) => accounts.currentAccounts
);

export const selectAccountsErrors = createSelector(
  [selectAccounts],
  (accounts) => accounts.error
);

export const selectAccountsLoading = createSelector(
  [selectAccounts],
  (accounts) => accounts.loading
);

export const selectHistoricalAccountBalances = createSelector(
  [selectAccounts],
  (accounts) => accounts.historicalAccountBalances
);

export const selectRecentAccountBalances = createSelector(
  [selectAccounts],
  (accounts) => accounts.recentAccountBalances
);
