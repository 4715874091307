import React from 'react';
import { Container, Segment, Header, Divider, Button, Grid, List, Breadcrumb, Dropdown, Icon, Checkbox, Label } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';

class ResponseList extends React.Component {
  render() {
    const { setCurrentView } = this.props;

    return (
      <Container>
        {/* <Divider hidden />

        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link >Back</Breadcrumb.Section>
        </Breadcrumb> */}

        <Header as="h1">
          <Header.Content>Manage Responses</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Survey Responses</Header.Content>
            </Header>
          </Segment>

          <Segment>

            <Header>
              <Header.Content>COVID-19 Self-Screening SMS Survey <Label color="black">Automated</Label></Header.Content>
              <Header.Subheader>Enable automated surveys for each shift as needed</Header.Subheader>
            </Header>

            <List divided verticalAlign='middle' size="large">
              <List.Item>
                <List.Content floated='right'>
                  <Button primary style={{ position: 'relative' }} onClick={() => setCurrentView("ResponseView")}>View Responses<Label circular color="red" className="buttonCircleNotification">9</Label></Button>
                </List.Content>
                <List.Content>
                  <List.Header as='a'>Shift 1 - 8AM</List.Header>
                  <List.Description as='a'>
                    <span style={{ marginRight: '6px' }}>9 Employees</span>
                    <Label color="orange" size="mini"><Icon name='exclamation triangle' />2 at Risk</Label>
                    <Label size="mini"><Icon name='circle question' />2 No Response</Label>
                    <Label color="green" size="mini"><Icon name='check' />5 Ready for Work</Label>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>

          </Segment>
          <Segment secondary>
            <Button content="Download Responses" labelPosition="left" icon="download"/>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseList);