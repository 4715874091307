// Define firebase config based on build level
const BUILD_LEVEL = 'stage';
let firebaseConfig = null;
let cloudFunctionUrl = null;

if (BUILD_LEVEL === "dev") {
  firebaseConfig = {
    apiKey: "AIzaSyBcnIGL6qdM86wXYs5n3kZOZYfWn_uIeug",
    authDomain: "atomscale-dev.firebaseapp.com",
    databaseURL: "https://atomscale-dev.firebaseio.com",
    projectId: "atomscale-dev",
    storageBucket: "atomscale-dev.appspot.com",
    messagingSenderId: "165081882721",
    appId: "1:165081882721:web:b30f0c60a5667a35168a20"
  };
  cloudFunctionUrl = "https://us-central1-atomscale-dev.cloudfunctions.net/";
} else if (BUILD_LEVEL === "stage") {
  firebaseConfig = {
    apiKey: "AIzaSyD97gIhZBMtWgZNrCfs7ac6HFkPnIuXneQ",
    authDomain: "atomscale-stage.firebaseapp.com",
    databaseURL: "https://atomscale-stage.firebaseio.com",
    projectId: "atomscale-stage",
    storageBucket: "atomscale-stage.appspot.com",
    messagingSenderId: "722866907397",
    appId: "1:722866907397:web:4a872f59c749b83ac66f6c",
    measurementId: "G-PXP838E5QS"
  };
  cloudFunctionUrl = "https://us-central1-atomscale-stage.cloudfunctions.net/";
} else if (BUILD_LEVEL === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyAQeAGM0nx-gK20Zi9-6SQWDOdEK4tO754",
    authDomain: "atomscale-prod.firebaseapp.com",
    databaseURL: "https://atomscale-prod.firebaseio.com",
    projectId: "atomscale-prod",
    storageBucket: "atomscale-prod.appspot.com",
    messagingSenderId: "168455690717",
    appId: "1:168455690717:web:5850777cfd6603e5b47e34",
    measurementId: "G-6K1VLN4RQ9"
  };
  cloudFunctionUrl = "https://us-central1-atomscale-prod.cloudfunctions.net/";
} else {
  console.error(`ATTENTION: No firebase build config exists for ${BUILD_LEVEL}`);
}

// Export firebase config
module.exports = { 
  BUILD_LEVEL,
  firebaseConfig,
  cloudFunctionUrl
};