import React, { useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import { usePlaidLink } from 'react-plaid-link';

const PlaidLinkButton = ({ onAddAccount, loading, disabled }) => {
  const onSuccess = useCallback((publicToken, accountMetadata) => {

    // Dispatch results to parent component
    onAddAccount({ publicToken, accountMetadata });

    // TBD - Catch duplicate accounts
    // const prevState = this.state.bankLinks
    // const newState = prevState.concat(metadata);
    // this.setState({
    //     bankLinks: newState
    // });

    // Save new account by sending public token to server
    // const { currentScenario, savePlaidAccount } = this.props;
    // savePlaidAccount({
    //   scenarioId: currentScenario.scenarioId,
    //   publicToken: token
    //   // currentUser.uid
    // });
  }, []);

  const config = {
    clientName:"Perl Street",
    env: "sandbox",
    product: ["auth", "transactions"],
    publicKey: "594b35d9757ddb1ffb809a083b108f",
    onSuccess,
    // onExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  return (
    <Button 
      color="blue" content="Add New Account" labelPosition="left" icon="add" 
      onClick={() => open()}
      disabled={!ready || disabled}
      loading={loading}
    />
  );
};
export default PlaidLinkButton;