import React from 'react';
import { Container, Header, Segment, Button, List, Message, Loader, Label, Divider } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import { selectCurrentUser } from '../../redux/user/user.selectors';
// import { setCurrentView } from '../../redux/view/view.actions';
import { getTransactionsStart } from '../../redux/transactions/transactions.actions';
import { selectTransactionsErrors, selectTransactionsLoading, selectCurrentTransactions } from '../../redux/transactions/transactions.selectors';

import AccountCreate from '../AccountCreate/AccountCreate.component';

class TransactionList extends React.Component {

  componentDidMount() {
    const { getTransactionsStart } = this.props;
    getTransactionsStart();
  }

  renderTransactionsList = () => {
    const { currentTransactions, transactionsLoading } = this.props;
    if (!Object.keys(currentTransactions).length) return (
      <Message>
        <Message.Header>No Account Transactions</Message.Header>
        <Message.Content>Link a new account on the Accounts tab.</Message.Content>
      </Message>
    );
    const accountIds = Object.keys(currentTransactions);
    return (
      accountIds.map(accountId => {
        const accountTransactions = currentTransactions[accountId];
        return (
          <React.Fragment key={`account-${accountId}`}>
            {/* <Button 
              floated="right" content="Unlink Account" icon="unlink" labelPosition="left" 
              compact style={{ display: 'inline' }} 
              onClick={() => deleteAccountStart(accountId)}
              loading={!!transactionsLoading}
              disabled={!!transactionsLoading}
            /> */}
            <Header as="h2" style={{ display: 'inline' }}>
              <Header.Content>{accountTransactions.institutionId} Accounts</Header.Content>
              <Header.Subheader>{accountTransactions.transactionsCount} Transactions</Header.Subheader>
            </Header>
            {/* <Divider /> */}
            {this.renderTransactionSubList(accountTransactions)}
          </React.Fragment>
        )
      })
    );
  }

  renderTransactionSubList = (accountTransactions) => {
    // const { setCurrentView } = this.props; 
    const transactionItems = accountTransactions.transactions;
    return (
      <List divided verticalAlign='middle' animated size="large">
        { transactionItems.map(transactionItem => {

            const itemId = transactionItem.transaction_id;
            const transactionName = transactionItem.name;
            const merchantName = transactionItem.merchant_name;
            let renderMerchantName = false;
            if (transactionName && merchantName) {
              if (transactionName.toLowerCase() !== merchantName.toLowerCase()) renderMerchantName = true;
            }
            
            return (
              <List.Item key={`transactionitem-${itemId}`}>
                {/* <List.Content floated='right'> */}
                  {/* <Button onClick={() => setCurrentView("AccountEdit", { itemId })}>Manage</Button> */}
                  {/* <List.Header><span style={{ color: "#afafaf", fontWeight: 'normal', fontSize: '0.75em' }}>****{accountItem.mask}</span></List.Header> */}
                {/* </List.Content> */}
                {/* <List.Icon name="building" size="large"/> */}
                <List.Content>
                  {/* {transactionItem.account_id} */}
                  <List.Description className="transactionDateItem">
                    {transactionItem.authorized_date || transactionItem.date}
                    <span className="transactionDetailTags">
                      {transactionItem.pending && <Label basic size="mini">Pending</Label>}
                      {transactionItem.category.map((cat, cat_i) => <Label size="mini" key={`${itemId}-cat${cat_i}`}>{cat}</Label>)}
                    </span>
                  </List.Description>
                  <List.Header>
                    {transactionItem.amount} {transactionItem.iso_currency_code || transactionItem.unofficial_currency_code} 
                    <span className="transactionDetailItem">{transactionItem.name}{renderMerchantName ? ` - ${transactionItem.merchant_name}` : null}</span></List.Header>
                </List.Content>
              </List.Item>
            )
        }) }
      </List>
    );
  }

  render() {
    const { transactionsErrors, transactionsLoading } = this.props;
    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Transactions</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>All Account Transactions</Header.Content>
            </Header>
          </Segment>

          <Segment>
            {transactionsLoading ? <Loader active inline='centered' size="small">Loading transaction data ...</Loader> : this.renderTransactionsList()}
          </Segment>
          <Segment secondary>
            <AccountCreate />
          </Segment>
        </Segment.Group>

        {transactionsErrors ? (
          <Message error>
            <Message.Header>{transactionsErrors.title}</Message.Header>
            <Message.Content>{transactionsErrors.error.message}</Message.Content>
          </Message>
        ) : null}

      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // currentUser: selectCurrentUser,
  transactionsErrors: selectTransactionsErrors,
  transactionsLoading: selectTransactionsLoading,
  currentTransactions: selectCurrentTransactions
});

const mapDispatchToProps = dispatch => ({
  // setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getTransactionsStart: () => dispatch(getTransactionsStart()),
  // deleteAccountStart: (accountId) => dispatch(deleteAccountStart(accountId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);