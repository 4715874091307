import { takeLatest, put, all, call } from 'redux-saga/effects';

import ModelsActionTypes from './models.types';

import { 
  addModelSuccess, addModelFailure,
  getModelsStart, getModelsSuccess, getModelsFailure,
  updateModelSuccess, updateModelFailure,
  deleteModelSuccess, deleteModelFailure,
  updateEmployeeModelSuccess, updateEmployeeModelFailure, getModelsOnDateStart, getModelsOnDateFailure, getModelsOnDateSuccess
} from './models.actions';

import { setCurrentView } from '../view/view.actions';

import { getEmployeesStart } from '../employees/employees.actions';

import {
  addModelDocument, getCurrentModelDocuments, updateModelDocument, deleteModelDocument, 
  updateEmployeeModelAssignment, getSelectedModelDocuments
} from '../../firebase/firebase.utils';

export function* addModel({ payload: { publicToken, models, institution, linkSessionId } }) {
  try {
    const newModelObject = yield addModelDocument(publicToken, models, institution, linkSessionId);
    yield put(addModelSuccess(newModelObject));
    yield put(setCurrentView("ModelList"));
  } catch (error) {
    yield put(addModelFailure(error));
  }
}

export function* onAddModelStart() {
  yield takeLatest(ModelsActionTypes.ADD_MODEL_START, addModel);
}

export function* getCurrentModels() {
  try {
    const currentModels = yield getCurrentModelDocuments();
    console.log("currentModels: ",currentModels);
    yield put(getModelsSuccess(currentModels));
  } catch (error) {
    yield put(getModelsFailure(error));
  }
}

export function* onGetCurrentModels() {
  yield takeLatest(ModelsActionTypes.GET_MODELS_START, getCurrentModels);
}

export function* updateModel({ payload: { modelId, modelData, currentCompany } }) {
  try {
    yield updateModelDocument(modelId, modelData);
    yield put(updateModelSuccess(modelData));
    yield put(getModelsStart({ currentCompany }));
  } catch (error) {
    yield put(updateModelFailure(error));
  }
}

export function* onUpdateModelStart() {
  yield takeLatest(ModelsActionTypes.UPDATE_MODEL_START, updateModel);
}

export function* deleteModel({ payload: { modelId } }) {
  try {
    yield deleteModelDocument(modelId);
    yield put(deleteModelSuccess(modelId));
    yield put(setCurrentView("ModelList"));
  } catch (error) {
    yield put(deleteModelFailure(error));
  }
}

export function* onDeleteModel() {
  yield takeLatest(ModelsActionTypes.DELETE_MODEL_START, deleteModel);
}

export function* updateEmployeeModel({ payload: { employeeId, modelId } }) {
  try {
    yield updateEmployeeModelAssignment(employeeId, modelId);
    yield put(updateEmployeeModelSuccess({ employeeId, modelId: modelId || "Unassigned" }));
  } catch (error) {
    yield put(updateEmployeeModelFailure(error));
  }
}

export function* onUpdateEmployeeModel() {
  yield takeLatest(ModelsActionTypes.UPDATE_EMPLOYEE_MODEL_START, updateEmployeeModel);
}

export function* getSelectedModels({ payload: { selectedDate } }) {
  try {
    const selectedModels = yield getSelectedModelDocuments(selectedDate);
    console.log(`selectedModels for date ${selectedDate}: `,selectedModels);
    yield put(getModelsOnDateSuccess(selectedModels));
  } catch (error) {
    yield put(getModelsOnDateFailure(error));
  }
}

export function* onGetSelectedModels() {
  yield takeLatest(ModelsActionTypes.GET_DAY_MODELS_START, getSelectedModels);
}

export function* modelsSagas() {
  yield all([
    call(onAddModelStart),
    call(onGetCurrentModels),
    call(onUpdateModelStart),
    call(onDeleteModel),
    call(onUpdateEmployeeModel),
    call(onGetSelectedModels)
  ]);
}