import React from 'react';
import { Container, Header, Segment, Button, List, Message, Loader, Label, Divider, Placeholder } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
// import { setCurrentView } from '../../redux/view/view.actions';
import { getAccountsStart, deleteAccountStart, getAccountBalancesStart } from '../../redux/accounts/accounts.actions';
import { selectAccountsErrors, selectAccountsLoading, selectCurrentAccounts, selectRecentAccountBalances } from '../../redux/accounts/accounts.selectors';

import AccountCreate from '../AccountCreate/AccountCreate.component';

class AccountList extends React.Component {

  componentDidMount() {
    const { getAccountsStart, getAccountBalancesStart } = this.props;
    getAccountsStart();
    getAccountBalancesStart();
  }

  renderAccountList = () => {
    const { currentAccounts, deleteAccountStart, accountsLoading } = this.props;
    if (!Object.keys(currentAccounts).length) return (
      <Message>
        <Message.Header>No Currently Linked Accounts</Message.Header>
        <Message.Content>Link a new account using the button below.</Message.Content>
      </Message>
    );
    const accountIds = Object.keys(currentAccounts);
    return (
      accountIds.map(accountId => {
        const accountData = currentAccounts[accountId];
        return (
          <React.Fragment key={`account-${accountId}`}>
            <Button 
              floated="right" content="Unlink Account" icon="unlink" labelPosition="left" 
              compact style={{ display: 'inline' }} 
              onClick={() => deleteAccountStart(accountId)}
              loading={!!accountsLoading}
              disabled={!!accountsLoading}
            />
            <Header as="h2" style={{ display: 'inline' }}>{accountData.institution.name} Accounts</Header>
            <Divider />
            {this.renderAccountSubList(accountData)}
          </React.Fragment>
        )
      })
    );
  }

  renderAccountSubList = (accountData) => {
    const { recentBalances } = this.props; 
    const accountItems = accountData.accounts;
    return (
      <List divided verticalAlign='middle' animated size="large">
        { accountItems.map(accountItem => {
          const itemId = accountItem.id;
          let lastBalance = null;
          let currencyCode = null;
          if (Object.keys(recentBalances).includes(itemId)) {
            lastBalance = recentBalances[itemId].balances.current;
            currencyCode = recentBalances[itemId].balances.iso_currency_code || recentBalances[itemId].balances.unofficial_currency_code;
          }
          return (
            <List.Item key={`accountitem-${itemId}`}>
              <List.Content floated='right'>
                {/* <Button onClick={() => setCurrentView("AccountEdit", { itemId })}>Manage</Button> */}
                <List.Header><span style={{ color: "#afafaf", fontWeight: 'normal', fontSize: '0.75em' }}>****{accountItem.mask}</span></List.Header>
              </List.Content>
              {/* <List.Icon name="building" size="large"/> */}
              <List.Content>
                <List.Header>{accountItem.name}</List.Header>
                <List.Description>
                { lastBalance ? (
                  <React.Fragment>
                  {lastBalance} {currencyCode}
                  {/* <Label size="mini">{accountItem.type}</Label><Label size="mini">{accountItem.subtype}</Label> */}
                  </React.Fragment>
                ) : (
                  <Placeholder>
                    <Placeholder.Line />
                  </Placeholder>
                )}
                </List.Description>
              </List.Content>
            </List.Item>
          )
        }) }
      </List>
    );
  }

  render() {
    const { accountsErrors, accountsLoading } = this.props;
    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Financial Accounts</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>All Linked Accounts</Header.Content>
            </Header>
          </Segment>

          <Segment>
            {accountsLoading ? <Loader active inline='centered' size="small">Loading account data ...</Loader> : this.renderAccountList()}
          </Segment>
          <Segment secondary>
            <AccountCreate />
          </Segment>
        </Segment.Group>

        {accountsErrors ? (
          <Message error>
            <Message.Header>{accountsErrors.title}</Message.Header>
            <Message.Content>{accountsErrors.error.message}</Message.Content>
          </Message>
        ) : null}

      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  accountsErrors: selectAccountsErrors,
  accountsLoading: selectAccountsLoading,
  currentAccounts: selectCurrentAccounts,
  recentBalances: selectRecentAccountBalances
});

const mapDispatchToProps = dispatch => ({
  // setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getAccountsStart: () => dispatch(getAccountsStart()),
  deleteAccountStart: (accountId) => dispatch(deleteAccountStart(accountId)),
  getAccountBalancesStart: () => dispatch(getAccountBalancesStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);