import { takeLatest, put, all, call } from 'redux-saga/effects';

import TransactionsActionTypes from './transactions.types';

import { 
  addTransactionSuccess, addTransactionFailure,
  getTransactionsStart, getTransactionsSuccess, getTransactionsFailure,
  updateTransactionSuccess, updateTransactionFailure,
  deleteTransactionSuccess, deleteTransactionFailure,
  updateEmployeeTransactionSuccess, updateEmployeeTransactionFailure, getTransactionsOnDateStart, getTransactionsOnDateFailure, getTransactionsOnDateSuccess
} from './transactions.actions';

import { setCurrentView } from '../view/view.actions';

import { getEmployeesStart } from '../employees/employees.actions';

import {
  addTransactionDocument, getCurrentTransactionDocuments, updateTransactionDocument, deleteTransactionDocument, 
  updateEmployeeTransactionAssignment, getSelectedTransactionDocuments
} from '../../firebase/firebase.utils';

export function* addTransaction({ payload: { publicToken, transactions, institution, linkSessionId } }) {
  try {
    const newTransactionObject = yield addTransactionDocument(publicToken, transactions, institution, linkSessionId);
    yield put(addTransactionSuccess(newTransactionObject));
    yield put(setCurrentView("TransactionList"));
  } catch (error) {
    yield put(addTransactionFailure(error));
  }
}

export function* onAddTransactionStart() {
  yield takeLatest(TransactionsActionTypes.ADD_TRANSACTION_START, addTransaction);
}

export function* getCurrentTransactions() {
  try {
    const currentTransactions = yield getCurrentTransactionDocuments();
    yield put(getTransactionsSuccess(currentTransactions));
  } catch (error) {
    yield put(getTransactionsFailure(error));
  }
}

export function* onGetCurrentTransactions() {
  yield takeLatest(TransactionsActionTypes.GET_TRANSACTIONS_START, getCurrentTransactions);
}

export function* updateTransaction({ payload: { transactionId, transactionData, currentCompany } }) {
  try {
    yield updateTransactionDocument(transactionId, transactionData);
    yield put(updateTransactionSuccess(transactionData));
    yield put(getTransactionsStart({ currentCompany }));
  } catch (error) {
    yield put(updateTransactionFailure(error));
  }
}

export function* onUpdateTransactionStart() {
  yield takeLatest(TransactionsActionTypes.UPDATE_TRANSACTION_START, updateTransaction);
}

export function* deleteTransaction({ payload: { transactionId } }) {
  try {
    yield deleteTransactionDocument(transactionId);
    yield put(deleteTransactionSuccess(transactionId));
    yield put(setCurrentView("TransactionList"));
  } catch (error) {
    yield put(deleteTransactionFailure(error));
  }
}

export function* onDeleteTransaction() {
  yield takeLatest(TransactionsActionTypes.DELETE_TRANSACTION_START, deleteTransaction);
}

export function* updateEmployeeTransaction({ payload: { employeeId, transactionId } }) {
  try {
    yield updateEmployeeTransactionAssignment(employeeId, transactionId);
    yield put(updateEmployeeTransactionSuccess({ employeeId, transactionId: transactionId || "Unassigned" }));
  } catch (error) {
    yield put(updateEmployeeTransactionFailure(error));
  }
}

export function* onUpdateEmployeeTransaction() {
  yield takeLatest(TransactionsActionTypes.UPDATE_EMPLOYEE_TRANSACTION_START, updateEmployeeTransaction);
}

export function* getSelectedTransactions({ payload: { selectedDate } }) {
  try {
    const selectedTransactions = yield getSelectedTransactionDocuments(selectedDate);
    console.log(`selectedTransactions for date ${selectedDate}: `,selectedTransactions);
    yield put(getTransactionsOnDateSuccess(selectedTransactions));
  } catch (error) {
    yield put(getTransactionsOnDateFailure(error));
  }
}

export function* onGetSelectedTransactions() {
  yield takeLatest(TransactionsActionTypes.GET_DAY_TRANSACTIONS_START, getSelectedTransactions);
}

export function* transactionsSagas() {
  yield all([
    call(onAddTransactionStart),
    call(onGetCurrentTransactions),
    call(onUpdateTransactionStart),
    call(onDeleteTransaction),
    call(onUpdateEmployeeTransaction),
    call(onGetSelectedTransactions)
  ]);
}