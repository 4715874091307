import ShiftsActionTypes from './shifts.types';
import EmployeeActionTypes from '../employees/employees.types';
import EmployeesActionTypes from '../employees/employees.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentShifts: null
}

const shiftsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeesActionTypes.UPDATE_EMPLOYEE_SUCCESS: {
      const prevShifts = { ...state.currentShifts };
      let newShifts = prevShifts;
      const shiftAssignment = action.payload.employeeData.shiftAssignment;
      if (shiftAssignment !== "Unassigned" && !prevShifts[shiftAssignment].assignedEmployees.includes(action.payload.employeeId)) {
        newShifts[shiftAssignment].assignedEmployees = [...prevShifts[shiftAssignment].assignedEmployees, action.payload.employeeId];
      }
      return {
        ...state,
        currentShifts: newShifts
      }
    }
    case EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS:
    {
      const prevShifts = { ...state.currentShifts };
      let newShifts = prevShifts;
      const shiftAssignment = action.payload.newEmployeeData.shiftAssignment;
      if (shiftAssignment !== "Unassigned" && !prevShifts[shiftAssignment].assignedEmployees.includes(action.payload.newEmployeeId)) {
        newShifts[shiftAssignment].assignedEmployees = [...prevShifts[shiftAssignment].assignedEmployees, action.payload.newEmployeeId];
      }
      return {
        ...state,
        currentShifts: newShifts
      }
    }
    case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS: {
      const prevShifts = { ...state.currentShifts };
      let newShifts = prevShifts;
      Object.keys(newShifts).forEach(shiftId => {
        if(prevShifts[shiftId].assignedEmployees.includes(action.payload.removedId)) {
          newShifts[shiftId].assignedEmployees = newShifts[shiftId].assignedEmployees.filter(id => id !== action.payload.removedId)
        }
      });
      return {
        ...state,
        currentShifts: {
          ...newShifts
        }
      }
    }
    case ShiftsActionTypes.DELETE_SHIFT_START:
    case ShiftsActionTypes.ADD_SHIFT_START:
    case ShiftsActionTypes.GET_SHIFTS_START:
    case ShiftsActionTypes.UPDATE_SHIFT_START:
      return {
        ...state,
        loading: true
      }
    case ShiftsActionTypes.UPDATE_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case ShiftsActionTypes.ADD_SHIFT_SUCCESS:
      return {
        ...state,
        currentShifts: {
          ...state.currentShifts,
          [action.payload.newShiftId]: action.payload.newShiftData
        },
        loading: false,
        error: null
      }
    case ShiftsActionTypes.DELETE_SHIFT_SUCCESS:
      return {
        ...state,
        currentShifts: action.payload.shiftData,
        loading: false,
        error: null
      }
    case ShiftsActionTypes.GET_SHIFTS_SUCCESS:
      return {
        ...state,
        currentShifts: action.payload.currentShifts,
        loading: false,
        error: null
      }
    case ShiftsActionTypes.UPDATE_SHIFT_FAILURE:
    case ShiftsActionTypes.DELETE_SHIFT_FAILURE:
    case ShiftsActionTypes.ADD_SHIFT_FAILURE:
    case ShiftsActionTypes.GET_SHIFTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong. Refresh the page and try again, or contact team@perlstreet.com",
          error: action.payload
        }
      }
    default:
      return state;
  }
}

export default shiftsReducer;