import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Message, Divider, Segment, Header, Button, Embed, Dimmer, Form } from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';

class BetaPassCode extends React.Component {
  state = { active: true, passcode: '', passcodeError: '' };

  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });
  handlePassCodeSubmit = () => {
    if (this.state.passcode === "PERLSTREET2020") {
      this.setState({ active: false, passcodeError: false });
    } else {
      this.setState({ passcode: '', passcodeError: true });
    }
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { active, passcode, passcodeError } = this.state
    const { signOutStart } = this.props;

    return (
      <Dimmer active={active} page style={{ overflow: 'scroll' }}>
          {/* <Header as='h2' icon inverted>
            <Icon name='heart' />
            Dimmed Message!
            <Header.Subheader>Dimmer sub-header</Header.Subheader>
          </Header> */}
        
        <Container>
          <Divider hidden />
          <Segment.Group stacked compact>
            <Segment>
              <Header as="h1">Thanks for signing up!</Header>
            </Segment>
            <Segment>
              <Message success compact>
                <Message.Header>You are up next on the waiting list.</Message.Header>
                <Message.Content>Thanks for completing your profile. We'll be reaching out soon!</Message.Content>
              </Message>
              {/* <Embed 
                id="vimeoIdGoesHere"
                placeholder="/media/thumbnails/video-Thumbnail.jpg"
                source="vimeo"
              /> */}
              <Header><Header.Subheader>Already received an access code from us? Enter it here:</Header.Subheader></Header>
              <Form size="large" onSubmit={this.handlePassCodeSubmit}>
                <Form.Input  
                  style={{ maxWidth: '250px' }}
                  icon='lock' 
                  iconPosition='left' 
                  placeholder='Enter Access Code' 
                  name="passcode" 
                  value={passcode}
                  onChange={this.handleChange} 
                />
                <Button primary type="submit">Submit</Button>
              </Form>
              {passcodeError && <Message error compact>
                <Message.Header>Incorrect Passcode.</Message.Header>
                <Message.Content>Please contact team@perlstreet.com to get your passcode.</Message.Content>
              </Message>}
            </Segment>
            <Segment secondary>
              <Button content="Return to Homepage" icon="sign out" labelPosition="right" onClick={() => signOutStart()}/>
            </Segment>
          </Segment.Group>
        </Container>
      </Dimmer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(BetaPassCode);