import React from 'react';
import PlaidLinkButton from '../PlaidLinkButton/PlaidLinkButton.component';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { addAccountStart } from '../../redux/accounts/accounts.actions';
import { selectAccountsLoading } from '../../redux/accounts/accounts.selectors';

class AccountCreate extends React.Component {
  onAddAccount = ({ publicToken, accountMetadata }) => {
    const { addAccountStart } = this.props;

    // Send token to server for exchange
    addAccountStart({
      publicToken,
      accounts: accountMetadata.accounts,
      institution: accountMetadata.institution,
      linkSessionId: accountMetadata.link_session_id
    })
    
  }

  render() {
    const { accountsLoading } = this.props;
    return(
      <PlaidLinkButton onAddAccount={this.onAddAccount} loading={!!accountsLoading} disabled={!!accountsLoading} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  accountsLoading: selectAccountsLoading
});

const mapDispatchToProps = dispatch => ({
  addAccountStart: (params) => dispatch(addAccountStart(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreate);