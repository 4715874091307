import React from 'react';
import { Container, Segment, Header, Divider, Button, Label, List, Breadcrumb, Dropdown, Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';

const options = [
  {
    key: 'today',
    text: 'today',
    value: 'today',
    content: 'Today',
  },
  {
    key: 'yesterday',
    text: 'yesterday',
    value: 'yesterday',
    content: 'Yesterday',
  },
  {
    key: 'another date',
    text: 'another date',
    value: 'another date',
    content: 'Custom date',
  },
]

class ResponseView extends React.Component {
  render() {
    const { setCurrentView } = this.props;

    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => setCurrentView("ResponseList")}>Back</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1">
          <Header.Content>COVID-19 Self-Screening Survey</Header.Content>
          <Header.Subheader>Shift 1 Survey Results<Label color="black">Automated</Label></Header.Subheader>
        </Header>

        <Header as='h4'>
          <Icon name='calendar alternate' />
          <Header.Content>
            Showing results for {' '}
            <Dropdown
              inline
              header='Show Results by Date'
              options={options}
              defaultValue={options[0].value}
            />
          </Header.Content>
        </Header>

        {/* <Segment placeholder>
          <Header icon>
            <Icon name='excel outline' />
            No users
          </Header>
          <Button primary>Upload .csv file</Button>
        </Segment> */}

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Survey Recipients</Header.Content>
              <Header.Subheader>Automated survey sent 15 minutes ago</Header.Subheader>
            </Header>
          </Segment>
          <Segment>

            <Header>Ready for Work</Header>

            <List divided verticalAlign='middle'>
              <List.Item>
                <List.Content floated='right'>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='check circle' color="green" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>John Hamston</List.Header>
                  <List.Description as='a'>Checked in 2 mins ago</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated='right'>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='check circle' color="green" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Jeremiah Salzburg</List.Header>
                  <List.Description as='a'>Checked in 3 mins ago</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated='right'>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='check circle' color="green" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Kate Clifford</List.Header>
                  <List.Description as='a'>Checked in 5 mins ago</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated='right'>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='check circle' color="green" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Sam Banks</List.Header>
                  <List.Description as='a'>Checked in 9 mins ago</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated='right'>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='check circle' color="green" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Sally Mayor</List.Header>
                  <List.Description as='a'>Checked in 14 mins ago</List.Description>
                </List.Content>
              </List.Item>
            </List>

            <Header>Potentially at Risk</Header>

            <List divided verticalAlign='middle'>
              <List.Item>
                <List.Content floated='right'>
                  <Button content="Contact" icon="phone" labelPosition="left"/>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='exclamation triangle' color="orange" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Roger McMurphy</List.Header>
                  <List.Description as='a'>Flagged risk factors 14 mins ago</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated='right'>
                  <Button content="Contact" icon="phone" labelPosition="left"/>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='exclamation triangle' color="orange" verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>James Sanders</List.Header>
                  <List.Description as='a'>Flagged risk factors 9 mins ago</List.Description>
                </List.Content>
              </List.Item>
            </List>

            <Header>Not Yet Responded</Header>

            <List divided verticalAlign='middle'>
              <List.Item>
                <List.Content floated='right'>
                  <Button content="Resend" icon="redo" labelPosition="left"/>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='question circle' verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Adrian DeSouza</List.Header>
                  <List.Description as='a'>Survey sent 15 mins ago</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated='right'>
                  <Button content="Resend" icon="redo" labelPosition="left"/>
                  <Button onClick={() => setCurrentView("EmployeeEdit")}>Manage</Button>
                </List.Content>
                <List.Icon name='question circle' verticalAlign='middle' />
                <List.Content>
                  <List.Header as='a'>Tim McConnery</List.Header>
                  <List.Description as='a'>Survey sent 15 mins ago</List.Description>
                </List.Content>
              </List.Item>
            </List>

          </Segment>
          <Segment secondary>
            <Button primary content="Assign New Employee to Shift" labelPosition="left" icon="plus" />
            <Button content="Download Responses" labelPosition="left" icon="download"/>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseView);