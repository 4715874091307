import { takeLatest, put, all, call } from 'redux-saga/effects';

import AccountsActionTypes from './accounts.types';

import { 
  addAccountSuccess, addAccountFailure,
  getAccountsStart, getAccountsSuccess, getAccountsFailure,
  updateAccountSuccess, updateAccountFailure,
  deleteAccountSuccess, deleteAccountFailure,
  updateEmployeeAccountSuccess, updateEmployeeAccountFailure, 
  getAccountBalancesSuccess, getAccountBalancesFailure, 
  getHistoricalAccountBalancesFailure, getHistoricalAccountBalancesSuccess
} from './accounts.actions';

import { setCurrentView } from '../view/view.actions';

import { getEmployeesStart } from '../employees/employees.actions';

import {
  addAccountDocument, getCurrentAccountDocuments, updateAccountDocument, deleteAccountDocument, updateEmployeeAccountAssignment,
  getRecentAccountBalances, pollHistoricalAccountBalances, getHistoricalAccountBalances
} from '../../firebase/firebase.utils';

export function* addAccount({ payload: { publicToken, accounts, institution, linkSessionId } }) {
  try {
    const newAccountObject = yield addAccountDocument(publicToken, accounts, institution, linkSessionId);
    yield put(addAccountSuccess(newAccountObject));
    yield put(setCurrentView("AccountList"));
  } catch (error) {
    yield put(addAccountFailure(error));
  }
}

export function* onAddAccountStart() {
  yield takeLatest(AccountsActionTypes.ADD_ACCOUNT_START, addAccount);
}

export function* getCurrentAccounts() {
  try {
    const currentAccounts = yield getCurrentAccountDocuments();
    yield put(getAccountsSuccess(currentAccounts));
  } catch (error) {
    yield put(getAccountsFailure(error));
  }
}

export function* onGetCurrentAccounts() {
  yield takeLatest(AccountsActionTypes.GET_ACCOUNTS_START, getCurrentAccounts);
}

export function* updateAccount({ payload: { accountId, accountData, currentCompany } }) {
  try {
    yield updateAccountDocument(accountId, accountData);
    yield put(updateAccountSuccess(accountData));
    yield put(getAccountsStart({ currentCompany }));
  } catch (error) {
    yield put(updateAccountFailure(error));
  }
}

export function* onUpdateAccountStart() {
  yield takeLatest(AccountsActionTypes.UPDATE_ACCOUNT_START, updateAccount);
}

export function* deleteAccount({ payload: { accountId } }) {
  try {
    yield deleteAccountDocument(accountId);
    yield put(deleteAccountSuccess(accountId));
    yield put(setCurrentView("AccountList"));
  } catch (error) {
    yield put(deleteAccountFailure(error));
  }
}

export function* onDeleteAccount() {
  yield takeLatest(AccountsActionTypes.DELETE_ACCOUNT_START, deleteAccount);
}

export function* updateEmployeeAccount({ payload: { employeeId, accountId } }) {
  try {
    yield updateEmployeeAccountAssignment(employeeId, accountId);
    yield put(updateEmployeeAccountSuccess({ employeeId, accountId: accountId || "Unassigned" }));
  } catch (error) {
    yield put(updateEmployeeAccountFailure(error));
  }
}

export function* onUpdateEmployeeAccount() {
  yield takeLatest(AccountsActionTypes.UPDATE_EMPLOYEE_ACCOUNT_START, updateEmployeeAccount);
}

export function* getCurrentBalances() {
  try {
    const currentBalances = yield getRecentAccountBalances();
    yield put(getAccountBalancesSuccess(currentBalances));
  } catch (error) {
    yield put(getAccountBalancesFailure(error));
  }
}

export function* onGetCurrentBalances() {
  yield takeLatest(AccountsActionTypes.GET_RECENT_BALANCES_START, getCurrentBalances);
}

export function* getHistoricalBalances() {
  try {
    // yield pollHistoricalAccountBalances();
    const historicalBalances = yield getHistoricalAccountBalances();
    yield put(getHistoricalAccountBalancesSuccess(historicalBalances));
  } catch (error) {
    yield put(getHistoricalAccountBalancesFailure(error));
  }
}

export function* onGetHistoricalBalances() {
  yield takeLatest(AccountsActionTypes.GET_HISTORICAL_BALANCES_START, getHistoricalBalances);
}

export function* accountsSagas() {
  yield all([
    call(onAddAccountStart),
    call(onGetCurrentAccounts),
    call(onUpdateAccountStart),
    call(onDeleteAccount),
    call(onUpdateEmployeeAccount),
    call(onGetCurrentBalances),
    call(onGetHistoricalBalances)
  ]);
}