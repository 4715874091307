import ViewActionTypes from './view.types';

const INITIAL_STATE = {
  currentView: "GettingStarted",
  viewData: {}
}

const viewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ViewActionTypes.SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.payload.currentView,
        viewData: action.payload.viewData
      };
    default:
      return state;
  }
}

export default viewReducer;