import { createSelector } from 'reselect';

const selectTransactions = state => state.transactions;

export const selectCurrentTransactions = createSelector(
  [selectTransactions],
  (transactions) => transactions.currentTransactions
);

export const selectTransactionsErrors = createSelector(
  [selectTransactions],
  (transactions) => transactions.error
);

export const selectTransactionsLoading = createSelector(
  [selectTransactions],
  (transactions) => transactions.loading
);

export const selectSelectedTransactions = createSelector(
  [selectTransactions],
  (transactions) => transactions.selectedTransactions
);