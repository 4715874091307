import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  List,
  Label
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './HowItWorksPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const WhyDebtHeading = ({ mobile }) => (
  <div className="howItWorksPageOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        <Header
          as='h2'
          content={
          <React.Fragment>
            <span style={{ fontFamily: 'Lora, serif' }}>How Does it Work<span style={{color: "rgb(0, 195, 255)" }}>?</span></span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        />
        <Header
          as='h2'
          content={
          <React.Fragment>
            Perl Street brings innovative data-driven financing structures to the startup world.
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        />

        {/* <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
          style={{
            marginTop: '1em'
          }}
        >
          Learn More About Debt
          <Icon name='right chevron' />
        </Button> */}
      </Container>
    </Segment>
  </div>
)

WhyDebtHeading.propTypes = {
  mobile: PropTypes.bool,
}

const WhyDebtPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <WhyDebtHeading {...props} />

      <Segment style={{ padding: '0em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Divider hidden />

          <Grid.Row reversed="computer">
            <Grid.Column width={8}>
              <Image src={'media/illustrations/FundraisingProcess-Labeled.png'} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '3em' }}>
                Our Process <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span>
              </Header>
              <List as='ol' size="huge">
                <List.Item as='li'>Build an optimal financing structure</List.Item>
                <List.Item as='li'>Unlock new capital to scale your assets</List.Item>
                <List.Item as='li'>Deploy new units and grow your business</List.Item>
                <List.Item as='li'>Hit key milestones and verify performance</List.Item>
              </List>
              <p><span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span> Infinitely repeat the above steps if desired</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Divider
                as='h4'
                className='header'
                horizontal
                style={{ margin: '1em 0em', textTransform: 'uppercase' }}
              >
                <p style={{ color: "#4183c4" }}>OFF-BALANCE SHEET FINANCING</p>
              </Divider>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} textAlign="center">
              <Image src={'media/illustrations/SPVStructuring.png'} centered style={{ marginTop: '3.5em' }} />
              <Label>Above is an example of just one of <span style={{ textDecoration: 'underline' }}>many different possible financing structures</span></Label>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '2.5em' }}>
                <Header.Content id="off-balance-sheet">
                  What is <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>Off-Balance Sheet </span>Capital?
                </Header.Content>
              </Header>

              <p className="hugeText">
                Imagine setting up a small company <span style={{ fontWeight: 'bold' }}>for the sole purpose of financing your project. </span>
                This allows your main company entity to <span style={{ textDecoration: 'underline' }}>use equity financing for other purposes</span>, like hiring.
                This structure allows you to:
              </p>

              <List as='ul' size="huge">
                <List.Item as='li'>Lower the cost of debt financing for capital intensive projects</List.Item>
                <List.Item as='li'>Keep the HoldCo in a good position for equity funraising</List.Item>
                <List.Item as='li'>Attract more lenders with high quality structuring</List.Item>
                <List.Item as='li'>Start with a scalable and repeatable financing structure</List.Item>
                <List.Item as='li'>Increase your valuation multiple by demonstrating scalability</List.Item>
              </List>

            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Divider
                as='h4'
                className='header'
                horizontal
                style={{ margin: '3em 0em', textTransform: 'uppercase' }}
              >
                <p style={{ color: "#4183c4" }}>NOW LET'S TALK ABOUT DEBT</p>
              </Divider>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <div className="whyDebtPageOverlay">
        <Segment style={{ padding: '0 0 5em 0' }} vertical>
          <Container text 
            textAlign="center"
            style={{
              paddingTop: '2em',
              paddingBottom: '1em'
            }}
          >
            <Header
              as='h2'
              content={
              <React.Fragment>
                <span style={{ fontFamily: 'Lora, serif' }}>But <span style={{color: "rgb(0, 195, 255)" }}>Am I Ready</span> for Debt?</span>
              </React.Fragment>}
              style={{
                fontSize: '3em',
                fontWeight: '700',
                marginTop: '0.5em',
                marginBottom: '0em'
              }}
            />
            <Header
              as='h2'
              content={
              <React.Fragment>
                Capital intensive problems need capital market solutions.<br/><strong>It pays to prepare for a proper capital structure early.</strong>
              </React.Fragment>}
              style={{
                fontSize: '1.7em',
                fontWeight: 'normal',
                marginTop: '1em',
                marginBottom: '0em'
              }}
            />

            {/* <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
              style={{
                marginTop: '1em'
              }}
            >
              Learn More About Debt
              <Icon name='right chevron' />
            </Button> */}
          </Container>
        </Segment>
      </div>

      <Segment style={{ padding: '0em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Grid.Row>
            <Grid.Column floated='left' width={7}>
              <Header as='h3' style={{ fontSize: '2.5em' }}>
                <Header.Content>
                Is <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>raising debt</span> in general really a good idea for us?
                </Header.Content>
              </Header>
              <p className="hugeText">Of course it always depends on your situation - but the short answer is often yes, <span style={{ textDecoration: 'underline' }}>especially for capital intensive companies</span>.</p>
              <p className="hugeText"><strong>Capital intensive companies are generally a poor match for most venture capital</strong>, which requires a high rate of return that's a lot easier to achieve without physical assets holding you down.</p>
              <p className="hugeText">Leveraging with debt allows for a higher return on equity, and more options for your company. <i>There are also creative ways to remove owned assets from the equation entirely!</i></p>
            </Grid.Column>
            <Grid.Column width={9} textAlign="center">
              <Image src={'media/illustrations/PlatformROEMath.png'} centered />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header as='h3' style={{ fontSize: '2.5em', padding: '1em 0' }} textAlign="center">
                <Header.Subheader>
                  Raising debt alongside your equity round allows you to maximize the bang for your buck.<br/>
                  It's generally better to utilize equity capital for higher ROI activities rather than inventory and materials.
                </Header.Subheader>
              </Header>
              <Image src={'media/illustrations/CapitalAllocationExample.png'} centered style={{ width: '100%', maxWidth: '500px' }} />
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Segment>

      <Footer />
    </AppContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(WhyDebtPage);