import AccountsActionTypes from './accounts.types';

// Add account

export const addAccountStart = ({ publicToken, accounts, institution, linkSessionId }) => ({
  type: AccountsActionTypes.ADD_ACCOUNT_START,
  payload: {
    publicToken,
    accounts,
    institution,
    linkSessionId
  }
});

export const addAccountSuccess = (newAccountObject) => ({
  type: AccountsActionTypes.ADD_ACCOUNT_SUCCESS,
  payload: newAccountObject
});

export const addAccountFailure = error => ({
  type: AccountsActionTypes.ADD_ACCOUNT_FAILURE,
  payload: error
});

// Get accounts

export const getAccountsStart = () => ({
  type: AccountsActionTypes.GET_ACCOUNTS_START
});

export const getAccountsSuccess = (currentAccounts) => ({
  type: AccountsActionTypes.GET_ACCOUNTS_SUCCESS,
  payload: {
    currentAccounts
  }
});

export const getAccountsFailure = error => ({
  type: AccountsActionTypes.GET_ACCOUNTS_FAILURE,
  payload: error
});

// Update ACCOUNT

export const updateAccountStart = ({ accountId, accountData, currentCompany }) => ({
  type: AccountsActionTypes.UPDATE_ACCOUNT_START,
  payload: {
    accountId,
    accountData,
    currentCompany
  }
});

export const updateAccountSuccess = (accountData) => ({
  type: AccountsActionTypes.UPDATE_ACCOUNT_SUCCESS,
  payload: {
    accountData
  }
});

export const updateAccountFailure = error => ({
  type: AccountsActionTypes.UPDATE_ACCOUNT_FAILURE,
  payload: error
});

// Delete account

export const deleteAccountStart = (accountId) => ({
  type: AccountsActionTypes.DELETE_ACCOUNT_START,
  payload: {
    accountId
  }
});

export const deleteAccountSuccess = (removedId) => ({
  type: AccountsActionTypes.DELETE_ACCOUNT_SUCCESS,
  payload: {
    removedId
  }
});

export const deleteAccountFailure = error => ({
  type: AccountsActionTypes.DELETE_ACCOUNT_FAILURE,
  payload: error
});

// Update employee account assignment

export const updateEmployeeAccountStart = ({ employeeId, accountId }) => ({
  type: AccountsActionTypes.UPDATE_EMPLOYEE_ACCOUNT_START,
  payload: {
    employeeId,
    accountId
  }
});

export const updateEmployeeAccountSuccess = ({ employeeId, accountId }) => ({
  type: AccountsActionTypes.UPDATE_EMPLOYEE_ACCOUNT_SUCCESS,
  payload: {
    employeeId,
    accountId
  }
});

export const updateEmployeeAccountFailure = error => ({
  type: AccountsActionTypes.UPDATE_EMPLOYEE_ACCOUNT_FAILURE,
  payload: error
});

// Get recent account balances

export const getAccountBalancesStart = () => ({
  type: AccountsActionTypes.GET_RECENT_BALANCES_START
});

export const getAccountBalancesSuccess = (recentBalances) => ({
  type: AccountsActionTypes.GET_RECENT_BALANCES_SUCCESS,
  payload: {
    recentBalances
  }
});

export const getAccountBalancesFailure = error => ({
  type: AccountsActionTypes.GET_RECENT_BALANCES_FAILURE,
  payload: error
});

// Get historical account balances

export const getHistoricalAccountBalancesStart = () => ({
  type: AccountsActionTypes.GET_HISTORICAL_BALANCES_START
});

export const getHistoricalAccountBalancesSuccess = (historicalBalances) => ({
  type: AccountsActionTypes.GET_HISTORICAL_BALANCES_SUCCESS,
  payload: {
    historicalBalances
  }
});

export const getHistoricalAccountBalancesFailure = error => ({
  type: AccountsActionTypes.GET_HISTORICAL_BALANCES_FAILURE,
  payload: error
});