import ModelsActionTypes from './models.types';
import EmployeesActionTypes from '../employees/employees.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentModels: {}
  // selectedModels: {}
}

const modelsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeesActionTypes.UPDATE_EMPLOYEE_SUCCESS: {
      const prevModels = { ...state.currentModels };
      let newModels = prevModels;
      const modelAssignment = action.payload.employeeData.modelAssignment;
      if (modelAssignment !== "Unassigned" && !prevModels[modelAssignment].assignedEmployees.includes(action.payload.employeeId)) {
        newModels[modelAssignment].assignedEmployees = [...prevModels[modelAssignment].assignedEmployees, action.payload.employeeId];
      }
      return {
        ...state,
        currentModels: newModels
      }
    }
    case EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS:
    {
      const prevModels = { ...state.currentModels };
      let newModels = prevModels;
      const modelAssignment = action.payload.newEmployeeData.modelAssignment;
      if (modelAssignment !== "Unassigned" && !prevModels[modelAssignment].assignedEmployees.includes(action.payload.newEmployeeId)) {
        newModels[modelAssignment].assignedEmployees = [...prevModels[modelAssignment].assignedEmployees, action.payload.newEmployeeId];
      }
      return {
        ...state,
        currentModels: newModels
      }
    }
    case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS: {
      const prevModels = { ...state.currentModels };
      let newModels = prevModels;
      Object.keys(newModels).forEach(modelId => {
        if(prevModels[modelId].assignedEmployees.includes(action.payload.removedId)) {
          newModels[modelId].assignedEmployees = newModels[modelId].assignedEmployees.filter(id => id !== action.payload.removedId)
        }
      });
      return {
        ...state,
        currentModels: {
          ...newModels
        }
      }
    }
    case ModelsActionTypes.DELETE_MODEL_START:
    case ModelsActionTypes.ADD_MODEL_START:
    case ModelsActionTypes.GET_MODELS_START:
    case ModelsActionTypes.UPDATE_MODEL_START:
    case ModelsActionTypes.GET_DAY_MODELS_START:
      return {
        ...state,
        loading: true
      }
    case ModelsActionTypes.UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case ModelsActionTypes.ADD_MODEL_SUCCESS:
      return {
        ...state,
        currentModels: {
          ...state.currentModels,
          ...action.payload
        },
        loading: false,
        error: null
      }
    case ModelsActionTypes.DELETE_MODEL_SUCCESS:
      const prevModels = { ...state.currentModels };
      let newModels = { ...prevModels };
      delete newModels[action.payload.removedId];
      return {
        ...state,
        currentModels: newModels,
        loading: false,
        error: null
      }
    case ModelsActionTypes.GET_MODELS_SUCCESS:
      return {
        ...state,
        currentModels: action.payload.currentModels,
        loading: false,
        error: null
      }
    case ModelsActionTypes.GET_DAY_MODELS_SUCCESS:
      return {
        ...state,
        selectedModels: action.payload.selectedModels,
        loading: false,
        error: null
      }
    case ModelsActionTypes.UPDATE_MODEL_FAILURE:
    case ModelsActionTypes.DELETE_MODEL_FAILURE:
    case ModelsActionTypes.ADD_MODEL_FAILURE:
    case ModelsActionTypes.GET_MODELS_FAILURE:
    case ModelsActionTypes.GET_DAY_MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong. Refresh and try again, or contact team@perlstreet.com",
          error: action.payload
        }
      }
    default:
      return state;
  }
}

export default modelsReducer;