import UserActionTypes from './user.types';
import OnboardingActionTypes from '../onboarding/onboarding.types';

const INITIAL_STATE = {
  currentUser: null,
  error: null,
  resetPassword: null,
  loading: false
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.CREATE_INITIAL_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentUser: {
          ...state.currentUser,
          linkedCompany: action.payload,
          onboardingCompleted: true
        }
      }
    case UserActionTypes.CREATE_INITIAL_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Could not complete initial company profile.",
          error: action.payload
        }
      }
    case UserActionTypes.CREATE_INITIAL_USERPROFILE_START:
    case UserActionTypes.CREATE_INITIAL_COMPANY_START:
      return {
        ...state,
        loading: true
      }
    case UserActionTypes.CREATE_INITIAL_USERPROFILE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        },
        loading: false,
        error: null
      }
    case UserActionTypes.CREATE_INITIAL_USERPROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Could not complete initial user profile.",
          error: action.payload
        }
      }
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        loading: false
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...INITIAL_STATE
      };
    case UserActionTypes.RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        error: null
      };
    case UserActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        error: {
          title: "Could not sign in.",
          error: action.payload
        },
        loading: false
      };
    case UserActionTypes.RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: {
          title: "Could not reset your password.",
          error: action.payload
        }
      };
    case UserActionTypes.SIGN_UP_START:
    case UserActionTypes.EMAIL_SIGN_IN_START:
      return {
        ...state,
        loading: true
      }
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        error: {
          title: "Could not create an account.",
          error: action.payload
        },
        loading: false
      };
    case UserActionTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        error: {
          title: "Something went wrong.",
          error: action.payload
        }
      };
    case OnboardingActionTypes.UPDATE_USERNAME_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          displayName: action.payload
        }
      }
    default:
      return state;
  }
}

export default userReducer;