import React from 'react';
import { Container, Header, Segment, Button, List, Message, Loader, Label, Divider } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
// import { setCurrentView } from '../../redux/view/view.actions';
import { getAccountsStart, deleteAccountStart, getHistoricalAccountBalancesStart } from '../../redux/accounts/accounts.actions';
import { selectAccountsErrors, selectAccountsLoading, selectCurrentAccounts, selectHistoricalAccountBalances } from '../../redux/accounts/accounts.selectors';
import { getTransactionsOnDateStart } from '../../redux/transactions/transactions.actions';
import { selectSelectedTransactions, selectTransactionsLoading } from '../../redux/transactions/transactions.selectors';

import AccountCreate from '../AccountCreate/AccountCreate.component';
import ProjectionsChart from '../ProjectionsChart/ProjectionsChart.component';

class ProjectionsList extends React.Component {

  handleChartClick = (data) => {
    console.log("Click handled: ",data);
    try {
      const selectedDate = data.points[0].x;
      if (!selectedDate) throw new Error("No date selected");

      // Send event data
      const { getTransactionsOnDateStart } = this.props;
      // getTransactionsOnDateStart(selectedDate);
      // TMP - Pass in a specific date for now
      getTransactionsOnDateStart("2020-06-23");
    } catch (e) {
      console.error("Could not send event data ",e);
    }
  }

  componentDidMount() {
    const { getAccountsStart, getHistoricalAccountBalancesStart } = this.props;
    getAccountsStart();
    getHistoricalAccountBalancesStart();
  }

  renderAccountList = () => {
    const { currentAccounts, deleteAccountStart, accountsLoading } = this.props;
    if (!Object.keys(currentAccounts).length) return (
      <Message>
        <Message.Header>No Currently Linked Accounts</Message.Header>
        <Message.Content>Link a new account using the button below.</Message.Content>
      </Message>
    );
    const accountIds = Object.keys(currentAccounts);
    return (
      accountIds.map(accountId => {
        const accountData = currentAccounts[accountId];
        return (
          <React.Fragment key={`account-${accountId}`}>
            <Button 
              floated="right" content="Unlink Account" icon="unlink" labelPosition="left" 
              compact style={{ display: 'inline' }} 
              onClick={() => deleteAccountStart(accountId)}
              loading={!!accountsLoading}
              disabled={!!accountsLoading}
            />
            <Header as="h2" style={{ display: 'inline' }}>{accountData.institution.name} Accounts</Header>
            <Divider />
            {this.renderAccountSubList(accountData)}
          </React.Fragment>
        )
      })
    );
  }

  renderAccountSubList = (accountData) => {
    // const { setCurrentView } = this.props; 
    const accountItems = accountData.accounts;
    return (
      <List divided verticalAlign='middle' animated size="large">
        { accountItems.map(accountItem => {
          const itemId = accountItem.id;
          return (
            <List.Item key={`accountitem-${itemId}`}>
              <List.Content floated='right'>
                {/* <Button onClick={() => setCurrentView("AccountEdit", { itemId })}>Manage</Button> */}
                <List.Header><span style={{ color: "#afafaf", fontWeight: 'normal', fontSize: '0.75em' }}>****{accountItem.mask}</span></List.Header>
              </List.Content>
              {/* <List.Icon name="building" size="large"/> */}
              <List.Content>
                <List.Header>{accountItem.name}</List.Header>
                <List.Description><Label size="mini">{accountItem.type}</Label><Label size="mini">{accountItem.subtype}</Label></List.Description>
              </List.Content>
            </List.Item>
          )
        }) }
      </List>
    );
  }

  renderTransactionSubList = (accountTransactions) => {
    const transactionItems = accountTransactions.transactions;
    return (
      <List divided verticalAlign='middle' animated size="large">
        { transactionItems.map(transactionItem => {

            const itemId = transactionItem.transaction_id;
            const transactionName = transactionItem.name;
            const merchantName = transactionItem.merchant_name;
            let renderMerchantName = false;
            if (transactionName && merchantName) {
              if (transactionName.toLowerCase() !== merchantName.toLowerCase()) renderMerchantName = true;
            }
            
            return (
              <List.Item key={`transactionitem-${itemId}`}>
                {/* <List.Content floated='right'> */}
                  {/* <Button onClick={() => setCurrentView("AccountEdit", { itemId })}>Manage</Button> */}
                  {/* <List.Header><span style={{ color: "#afafaf", fontWeight: 'normal', fontSize: '0.75em' }}>****{accountItem.mask}</span></List.Header> */}
                {/* </List.Content> */}
                {/* <List.Icon name="building" size="large"/> */}
                <List.Content>
                  {/* {transactionItem.account_id} */}
                  <List.Description className="transactionDateItem">
                    {transactionItem.authorized_date || transactionItem.date}
                    <span className="transactionDetailTags">
                      {transactionItem.pending && <Label basic size="mini">Pending</Label>}
                      {transactionItem.category.map((cat, cat_i) => <Label size="mini" key={`${itemId}-cat${cat_i}`}>{cat}</Label>)}
                    </span>
                  </List.Description>
                  <List.Header>
                    {transactionItem.amount} {transactionItem.iso_currency_code || transactionItem.unofficial_currency_code} 
                    <span className="transactionDetailItem">{transactionItem.name}{renderMerchantName ? ` - ${transactionItem.merchant_name}` : null}</span></List.Header>
                </List.Content>
              </List.Item>
            )
        }) }
      </List>
    );
  }

  renderSelectedTransactions = () => {
    const { selectedTransactions, transactionsLoading } = this.props;
    if (!selectedTransactions) return null;
    if (!Object.keys(selectedTransactions).length) return null;
    return (
      <Segment.Group>
        <Segment>
          <Header>Selected Transactions</Header>
        </Segment>
        <Segment>
        { transactionsLoading ? 
          <Loader active inline='centered' size="small">Loading transactions ...</Loader> : 
          Object.keys(selectedTransactions).map(accountId => (
          <React.Fragment key={accountId}>
            {this.renderTransactionSubList(selectedTransactions[accountId])}
          </React.Fragment>
          ))
        }
        </Segment>
      </Segment.Group>
    );
  }

  render() {
    const { accountsErrors, accountsLoading, historicalAccountBalances } = this.props;
    return (
      <Container>

        <Segment.Group>
          <Segment>
            <Header>Cash Projections vs Actuals</Header>
          </Segment>
          <Segment>
          {
            accountsLoading ? 
            <Loader active inline='centered' size="small">Loading projections ...</Loader> : 
            <ProjectionsChart historicalAccountBalances={historicalAccountBalances} handleClick={this.handleChartClick} />
          }
          </Segment>
          <Segment secondary>
            <Button primary icon="edit" labelPosition="left" content="Update Projections" loading={!!accountsLoading} disabled={!!accountsLoading} />
            <Button primary icon="alarm" labelPosition="left" content="Manage Alerts" loading={!!accountsLoading} disabled={!!accountsLoading} />
          </Segment>
        </Segment.Group>

        {this.renderSelectedTransactions()}

        {/* <Header as="h1">
          <Header.Content>Manage Financial Accounts</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>All Linked Accounts</Header.Content>
            </Header>
          </Segment>

          <Segment>
            {accountsLoading ? <Loader active inline='centered' size="small">Loading account data ...</Loader> : this.renderAccountList()}
          </Segment>
          <Segment secondary>
            <AccountCreate />
          </Segment>
        </Segment.Group> */}

        {accountsErrors ? (
          <Message error>
            <Message.Header>{accountsErrors.title}</Message.Header>
            <Message.Content>{accountsErrors.error.message}</Message.Content>
          </Message>
        ) : null}

      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  accountsErrors: selectAccountsErrors,
  accountsLoading: selectAccountsLoading,
  currentAccounts: selectCurrentAccounts,
  historicalAccountBalances: selectHistoricalAccountBalances,
  transactionsLoading: selectTransactionsLoading,
  selectedTransactions: selectSelectedTransactions
});

const mapDispatchToProps = dispatch => ({
  // setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getAccountsStart: () => dispatch(getAccountsStart()),
  deleteAccountStart: (accountId) => dispatch(deleteAccountStart(accountId)),
  getHistoricalAccountBalancesStart: () => dispatch(getHistoricalAccountBalancesStart()),
  getTransactionsOnDateStart: (selectedDate) => dispatch(getTransactionsOnDateStart(selectedDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionsList);