import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Divider, Menu, Segment, Sidebar, Button, Image } from 'semantic-ui-react'

import BrandColors from '../../utils/brandColors';
import './AdminPanel.styles.css';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.component';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import { setCurrentView } from '../../redux/view/view.actions';
import { setCurrentCompany } from '../../redux/company/company.actions';

import BetaPassCode from '../BetaPassCode/BetaPassCode.component';
import CompleteInitialUserProfile from '../CompleteInitialUserProfile/CompleteInitialUserProfile.component';
import CreateInitialCompany from '../CreateInitialCompany/CreateInitialCompany';

import GettingStarted from '../GettingStarted/GettingStarted.component';
import OrganizationsList from '../OrganizationsList/OrganizationsList.component';
import AccountList from '../AccountList/AccountList.component';
import TransactionList from '../TransactionList/TransactionList.component';
import ProjectionsList from '../ProjectionsList/ProjectionsList.component';
import ModelsList from '../ModelsList/ModelsList.component';

import EmployeeList from '../EmployeeList/EmployeeList.component';
import EmployeeEdit from '../EmployeeEdit/EmployeeEdit.component';
import EmployeeCreate from '../EmployeeCreate/EmployeeCreate.component';
import ShiftList from '../ShiftList/ShiftList.component';
import ShiftEdit from '../ShiftEdit/ShiftEdit.component';
import ShiftCreate from '../ShiftCreate/ShiftCreate.component';
import SurveyList from '../SurveyList/SurveyList.component';
import ResponseList from '../ResponseList/ResponseList.component';
import ResponseView from '../ResponseView/ResponseView.component';

// Create sidebar options
const UserDropdownActions = ({ currentUser, signOutStart }) => (
  <Dropdown item icon="sign out">
    <Dropdown.Menu>
      <Dropdown.Header>Currently Logged In As</Dropdown.Header>
      <Dropdown.Header>{currentUser.displayName || currentUser.email}</Dropdown.Header>
      <Dropdown.Item onClick={() => signOutStart()}><Icon name="sign-out"/> Sign Out</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

class AdminPanel extends React.Component {
    state = { 
      sideBarVisible: true, 
      activeMenuItem: 'home' 
    };

    componentDidMount() {
      const { currentUser, setCurrentView, setCurrentCompany } = this.props;
      if (!currentUser.onboardingCompleted && !this.isOnboardingView()) {
        setCurrentView("CompleteInitialUserProfile");
      } else {
        setCurrentCompany(currentUser.linkedCompany);
      }
    }

    isOnboardingView = () => {
      const { view } = this.props;
      switch (view.currentView) {
        case 'CompleteInitialUserProfile':
          return true;
        case 'CreateInitialCompany':
          return true;
        default:
          return false;
      }
    }

    renderMenuOptions = () => {
      const { view } = this.props;
      switch (view.currentView) {
        case 'OrganizationsList':
          return <OrganizationsList />;
        case 'AccountList':
          return <AccountList />;
        case 'TransactionList':
          return <TransactionList />;
        case 'ProjectionsList':
          return <ProjectionsList />;
        case 'ModelsList':
          return <ModelsList />;
        case 'CompleteInitialUserProfile':
          return <CompleteInitialUserProfile />;
        case 'CreateInitialCompany':
          return <CreateInitialCompany />;
        case 'GettingStarted':
          return <GettingStarted />;
        case 'SurveyList':
          return <SurveyList />;
        case 'EmployeeList':
          return <EmployeeList />;
        case 'EmployeeEdit':
          return <EmployeeEdit />;
        case 'EmployeeCreate':
          return <EmployeeCreate />;
        case 'ShiftList':
          return <ShiftList />;
        case 'ShiftEdit':
          return <ShiftEdit />;
        case 'ShiftCreate':
          return <ShiftCreate />;
        case 'ResponseList':
          return <ResponseList />
        case 'ResponseView':
          return <ResponseView />
        default:
          return 'Page not found. Please contact team@perlstreet.com';
      }
    }

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    toggleSidebarVisibility = () => this.setState({ sideBarVisible: !this.state.sideBarVisible });

    render() {
      const { sideBarVisible } = this.state
      const { currentUser, signOutStart, view, setCurrentView, currentCompany } = this.props;
      const isOnboardingView = this.isOnboardingView();
  
      return (
      <React.Fragment>
      <Menu
        style={{ borderRadius: 0, margin: 0 }}
      >

        <Menu.Item as="a" style={{ padding: 0, border: 'none' }} onClick={() => setCurrentView("GettingStarted")}>
          <Image src={"/logos/logo_ps_b_20x.png"} className="navLogo" />
        </Menu.Item>

        <Menu.Menu position='right'>

          { currentUser ? 
            <UserDropdownActions currentUser={currentUser} signOutStart={signOutStart} />
            :
            <React.Fragment>
              <Menu.Item as={Link} to="/login">
                Login
              </Menu.Item>
              <Menu.Item>
                <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} style={{ marginLeft: '0.5em' }} className="round">
                  Get Started
                  <Icon name='right chevron' />
                </Button>
              </Menu.Item>
            </React.Fragment>
          }

        </Menu.Menu>

      </Menu>

      { isOnboardingView ? (
        this.renderMenuOptions()
      ) : (
        !currentCompany ? ( <LoadingSpinner message="Loading your company data..." /> ) : (
        <Sidebar.Pushable as={Segment} style={{ minHeight: '100vh', margin: 0, borderRadius: 0, background: 'whitesmoke' }}>
          <Sidebar
            visible={sideBarVisible}
            as={Menu}
            animation="overlay"
            width="thin"
            // icon="labeled"
            className="overflowingSidebar compressedSidebar"
            vertical
            inverted
          >

            <Button className="item" onClick={this.toggleSidebarVisibility} floated="right" compact icon="sidebar" style={{ zIndex: 1000, width: '100%' }}/>
          
            <Divider hidden />

            {/* <Menu.Item>
              Home
              <Menu.Menu>
                <Menu.Item
                  name='search'
                  active={activeMenuItem === 'search'}
                  onClick={this.handleMenuItemClick}
                >
                  Search
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item> */}

            {/* <Menu.Item
              name='dashboard'
              active={activeMenuItem === 'dashboard'}
              onClick={this.handleMenuItemClick}
            >
              <Icon name='grid layout' />
              Dashboard
            </Menu.Item> */}

            <Menu.Item
              name='home'
              active={view.currentView === 'GettingStarted'}
              onClick={() => setCurrentView("GettingStarted")}
            >
              <Icon name='home' />
              Home
            </Menu.Item>

            {/* <Menu.Item
              active={view.currentView === 'OrganizationsList'}
              onClick={() => setCurrentView("OrganizationsList")}
            >
              <Icon name='sitemap' />
              Organizations
            </Menu.Item> */}

            {/* <Menu.Item
              active={view.currentView === 'DepartmentsList'}
              onClick={() => setCurrentView("DepartmentsList")}
            >
              <Icon name='building' />
              Departments
            </Menu.Item> */}

            <Menu.Item
              name='accounts'
              active={view.currentView === 'AccountList'}
              onClick={() => setCurrentView("AccountList")}
            >
              <Icon name='briefcase' />
              Accounts
            </Menu.Item>

            {/* <Menu.Item
              name='employees'
              active={view.currentView === 'EmployeeList'}
              onClick={() => setCurrentView("EmployeeList")}
            >
              <Icon name='user' />
              Employees
            </Menu.Item> */}

            <Menu.Item
              active={view.currentView === 'TransactionList'}
              onClick={() => setCurrentView("TransactionList")}
            >
              <Icon name='dollar' />
              Transactions
            </Menu.Item>

            <Menu.Item
              active={view.currentView === 'ModelsList'}
              onClick={() => setCurrentView("ModelsList")}
            >
              <Icon name='excel file' />
              Financial Models
            </Menu.Item>

            <Menu.Item
              active={view.currentView === 'ProjectionsList'}
              onClick={() => setCurrentView("ProjectionsList")}
            >
              <Icon name='signal' />
              Projections
            </Menu.Item>

            <Menu.Item
              active={view.currentView === 'DeviceList'}
              onClick={() => setCurrentView("DeviceList")}
            >
              <Icon name='microchip' />
              Devices
            </Menu.Item>

            {/* <Menu.Item
              name='shifts'
              active={view.currentView === 'ShiftList'}
              onClick={() => setCurrentView("ShiftList")}
            >
              <Icon name='calendar alternate' />
              Shifts
            </Menu.Item>

            <Menu.Item
              name='surveys'
              active={view.currentView === 'SurveyList'}
              onClick={() => setCurrentView("SurveyList")}
            >
              <Icon name='tasks' />
              Surveys
            </Menu.Item>

            <Menu.Item
              name='responses'
              active={view.currentView === 'ResponseList'}
              onClick={() => setCurrentView("ResponseList")}
            >
              <Icon name='conversation' />
              Responses
            </Menu.Item> */}

            {/* <UserDropdownActions currentUser={currentUser} signOutStart={signOutStart} /> */}
            
            {/* <Dropdown item text='More'>
              <Dropdown.Menu>
                <Dropdown.Item icon='edit' text='Edit Profile' />
                <Dropdown.Item icon='globe' text='Choose Language' />
                <Dropdown.Item icon='settings' text='Account Settings' />
              </Dropdown.Menu>
            </Dropdown> */}

          </Sidebar>
        <Sidebar.Pusher style={{ paddingLeft: sideBarVisible ? 150 : 0, overflow: 'visible' }} className={`animatedPushable ${sideBarVisible ? "pushed" : null}`}>
          <Segment basic>

            {sideBarVisible ? null : (
              <Button className="item" onClick={this.toggleSidebarVisibility} floated="right" compact icon="sidebar" />
            )}

            <BetaPassCode />

            {this.renderMenuOptions()}

          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      )
      )}

    </React.Fragment>
    ) ;
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  setCurrentCompany: (companyId) => dispatch(setCurrentCompany(companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);