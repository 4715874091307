
import axios from 'axios';
import { cloudFunctionUrl } from '../firebase/firebase.config';

const headers = {
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
  }
};

export const cloudFunctions = axios.create({
  baseURL: cloudFunctionUrl
}, headers);