import React from 'react';
import { 
  Container, Segment, Header, Divider, Button, Grid, List, Breadcrumb, 
  Dropdown, Icon, Checkbox, Modal, Form, Message, Label
} from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { selectCurrentEmployees, selectEmployeesErrors, selectEmployeesLoading } from '../../redux/employees/employees.selectors';
import { getEmployeesStart } from '../../redux/employees/employees.actions';
import { selectCurrentShifts, selectShiftsErrors, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { deleteShiftStart, updateShiftStart, updateShiftFailure } from '../../redux/shifts/shifts.actions';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.component';
import TextLinkButton from '../text-link-button/TextLinkButton.component';
import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';
import ShiftAssignEmployeeModal from '../ShiftAssignEmployeeModal/ShiftAssignEmployeeModal.component';

// const timeZoneOptions = moment.tz.names().map(tz => ({ key: tz, value: tz, text: tz }));
const timeZoneOptions = moment.tz.zonesForCountry('US').map(tz => ({ key: tz, value: tz, text: tz }));

class ShiftEdit extends React.Component {
  state = {
    confirmDeleteOpen: false,
    
    shiftName: '',
    shiftTimezone: moment.tz.guess(),
    shiftStart: '08:00',
    shiftSendAuto: true,
    shiftNotificationTime: 60,
    assignedEmployees: [],

    assignEmployeeModalOpen: false,
  }

  componentDidMount() {
    const { 
      currentCompany, getEmployeesStart,
      view, currentShifts
    } = this.props;

    getEmployeesStart({ currentCompany });

    const shiftId = view.viewData.shiftId;
    const shift = currentShifts[shiftId];
    if (shift) {
      this.setState({
        shiftName: shift.shiftName,
        shiftTimezone: shift.shiftTimezone,
        shiftStart: shift.shiftStart,
        shiftSendAuto: shift.shiftSendAuto,
        shiftNotificationTime: shift.shiftNotificationTime,
        assignedEmployees: shift.assignedEmployees
      });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentCompany, updateShiftStart, updateShiftFailure, view } = this.props;
    const { shiftName, shiftTimezone, shiftStart, shiftSendAuto, shiftNotificationTime, assignedEmployees } = this.state;

    const shiftId = view.viewData.shiftId;
    
    if ( !shiftId || !shiftName || !shiftTimezone || !shiftStart ) {
      updateShiftFailure({
        code: "custom/failed-update-shift",
        message: "Please fill out all required fields"
      })
      return;
    }

    updateShiftStart({ 
      shiftId,
      shiftData: {
        shiftName, 
        shiftTimezone, 
        shiftStart, 
        shiftSendAuto,
        shiftNotificationTime,
        assignedEmployees
      },
      currentCompany
    });
  }

  toggleAssignId = (employeeId) => {
    const { assignedEmployees } = this.state;
    let newIds = [ ...assignedEmployees, employeeId ];
    if (assignedEmployees.includes(employeeId)) {
      newIds = assignedEmployees.filter(id => id !== employeeId);
    }
    this.setState({ assignedEmployees: newIds });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  showConfirmDelete = () => this.setState({ confirmDeleteOpen: true });
  closeConfirmDelete = () => this.setState({ confirmDeleteOpen: false });

  updateAssignedEmployees = (employeeIds) => this.setState({ assignedEmployees: employeeIds });

  openAssignEmployeeModal = () => this.setState({ assignEmployeeModalOpen: true });
  closeAssignEmployeeModal = () => this.setState({ assignEmployeeModalOpen: false });

  renderEmployeeSublist = () => {
    const { assignedEmployees } = this.state;
    const { currentEmployees, setCurrentView } = this.props
    if (!currentEmployees) return null;
    return (
      <List divided verticalAlign='middle' animated size="large">
        { !currentEmployees ? null : (
        <React.Fragment>
        { assignedEmployees.map(employeeId => (
          <List.Item key={employeeId}>
            <List.Content floated='right'>
              <Dropdown text='Manage' direction="left">
                <Dropdown.Menu>
                  <Dropdown.Item icon='edit' text='Manage employee' onClick={() => setCurrentView("EmployeeEdit", { employeeId })}/>
                  <Dropdown.Divider />
                  <Dropdown.Item icon='cancel' text='Unassign from shift' onClick={() => this.toggleAssignId(employeeId)}/>
                </Dropdown.Menu>
              </Dropdown>
            </List.Content>
            <List.Icon name="user circle" size="large"/>
            <List.Content>
              {currentEmployees[employeeId].employeeName}
            </List.Content>
          </List.Item>
        )) }
        </React.Fragment>
        ) }
      </List>
    );
  }

  render() {
    const { 
      setCurrentView, view, 
      currentCompany, 
      currentEmployees, employeesLoading,
      currentShifts, shiftsLoading, shiftsErrors,
      deleteShiftStart
    } = this.props;
    const { 
      confirmDeleteOpen, 
      shiftName, shiftTimezone, shiftStart, shiftSendAuto, shiftNotificationTime, assignedEmployees,
      assignEmployeeModalOpen
    } = this.state;
    const shiftId = view.viewData.shiftId;
    const shift = currentShifts[shiftId];
    return (
      <Container>

        { shift ? (

        <React.Fragment>

        <ShiftAssignEmployeeModal 
          isOpen={assignEmployeeModalOpen} 
          onClose={this.closeAssignEmployeeModal} 
          assignedIds={assignedEmployees} 
          currentEmployees={currentEmployees}
          toggleAssignId={this.toggleAssignId}
          onSubmit={(ids) => this.updateAssignedEmployees(ids)} 
        />

        <Modal size="small" open={confirmDeleteOpen} onClose={this.closeConfirmDelete}>
          <Modal.Header><Header color="red"><Icon name="exclamation triangle" color="red"/>Confirm Delete Shift</Header></Modal.Header>
          <Modal.Content style={{ textAlign: 'center' }}>
            <p>Are you sure you want to delete this shift?</p>
            <Header as="h2">
              <Header.Subheader>
                {shift.shiftName}
                <br/>
                {`${shift.assignedEmployees.length || "No"} assigned employees`}
                <br/>
                <Label size="mini" style={{ marginTop: '6px' }} image basic>
                  <Icon name='stopwatch' />
                  Starts
                  <Label.Detail>{moment(shift.shiftStart,"HH:mm").tz(shift.shiftTimezone).format("h:mm A z")}</Label.Detail>
                </Label>
              </Header.Subheader>
            </Header>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeConfirmDelete} disabled={!!shiftsLoading}>Cancel</Button>
            <Button
              negative
              icon='trash'
              labelPosition='right'
              content='Delete Shift'
              onClick={ () => deleteShiftStart({ currentCompany, currentShifts, shiftId })}
              disabled={!!shiftsLoading}
              loading={!!shiftsLoading}
            />
          </Modal.Actions>
        </Modal>

        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => setCurrentView("ShiftList")}>Back</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1">
          <Header.Content>Edit Shift</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>
                {shift.shiftName}
                <Label size="mini" style={{ marginLeft: '6px' }} image basic>
                  <Icon name='stopwatch' />
                  Starts
                  <Label.Detail>{moment(shift.shiftStart,"HH:mm").tz(shift.shiftTimezone).format("h:mm A z")}</Label.Detail>
                </Label>
                { shift.shiftSendAuto ? (
                  <Label size="mini" color="orange" style={{ marginLeft: '6px' }} image>
                    <Icon name='send' />
                    Autosend
                    <Label.Detail>{moment(shift.shiftStart,"HH:mm").tz(shift.shiftTimezone).subtract(+shift.shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                  </Label>
                ) : null }
              </Header.Content>
              <Header.Subheader>{`${shift.assignedEmployees.length || "No"} assigned employees`}</Header.Subheader>
            </Header>
          </Segment>

          <Segment>

            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>

                <Form size="large" onSubmit={this.handleSubmit} loading={!!shiftsLoading}>

                  <Header>Shift Settings</Header>

                  <Form.Input
                    fluid
                    label="Shift Name"
                    autoComplete="off"
                    icon='tag' 
                    iconPosition='left' 
                    placeholder='Enter a name for this shift e.g. Shift 1' 
                    required
                    name="shiftName" 
                    value={shiftName}
                    onChange={this.handleChange} 
                  />

                  <Form.Field required>
                    <label>Shift Timezone</label>
                    <Header style={{ marginTop: 0 }}><Header.Subheader>Current time in {shiftTimezone}: {moment().tz(shiftTimezone).format("h:mm A z")}</Header.Subheader></Header>
                    <Dropdown
                      autoComplete="off"
                      search
                      className="icon"
                      labeled
                      floating
                      selection
                      header='Select shift timezone'
                      options={timeZoneOptions}
                      name="shiftTimezone"
                      value={shiftTimezone}
                      onChange={this.handleSelectChange} 
                    />
                  </Form.Field>

                  <Form.Input
                    fluid
                    required
                    label="Shift Start Time"
                    type="time"
                    icon="clock"
                    iconPosition="left"
                    autoComplete="off"
                    name="shiftStart" 
                    value={shiftStart}
                    onChange={this.handleChange}
                  />

                  <Divider hidden />

                  <Header>Survey Notification Settings</Header>

                  <Form.Field>
                    <Checkbox 
                      label='Automatically send surveys prior to shift start'
                      toggle
                      name="shiftSendAuto"
                      checked={shiftSendAuto}
                      onChange={() => this.setState({ shiftSendAuto: !shiftSendAuto })} 
                    />
                  </Form.Field>

                  <Form.Input
                    fluid
                    label={
                      <React.Fragment>
                      <label>Notification Schedule</label>
                      <Header style={{ marginTop: 0 }}>
                        <Header.Subheader>
                          Send surveys{' '}
                          {+shiftNotificationTime === 0 ? " exactly at " : (
                            <React.Fragment>
                            {+shiftNotificationTime > 0 ? +shiftNotificationTime : -+shiftNotificationTime} mins 
                            {+shiftNotificationTime > 0 ? " prior to " : " after "} 
                            </React.Fragment>
                          )}
                          shift start ({moment(shiftStart,"HH:mm").tz(shiftTimezone).subtract(+shiftNotificationTime, 'minutes').format("h:mm A")})
                        </Header.Subheader>
                      </Header>
                      </React.Fragment>
                    }
                    type="number"
                    icon="bell"
                    iconPosition="left"
                    autoComplete="off"
                    // placeholder='Employee Name' 
                    // required
                    name="shiftNotificationTime" 
                    value={shiftNotificationTime}
                    onChange={this.handleChange}
                  />

                  </Form>

                  {
                  shiftsErrors ? (
                    <Message error>
                      <Message.Header>Could not update shift.</Message.Header>
                      { renderCustomizedErrorMessage(shiftsErrors.error.code, shiftsErrors.error.message) }
                    </Message>
                  ) : null
                  }

                </Grid.Column>

                <Grid.Column>

                  <Header>
                    <Header.Content>Assign Employees</Header.Content>
                    <Header.Subheader>Note: Employees won't be reassigned until changes are saved.</Header.Subheader>
                  </Header>

                  <Button content="Manage Assigned Employees" icon="edit" labelPosition="left" onClick={this.openAssignEmployeeModal} />

                  { assignedEmployees ? (
                    <Segment basic loading={!!employeesLoading}>
                      {this.renderEmployeeSublist()}
                    </Segment>
                  ) : (
                    <Message>
                      <Message.Content>No employees are currently assigned to this shift</Message.Content>
                    </Message>
                  )}

                  </Grid.Column>

              </Grid.Row>
            </Grid>

          </Segment>
          <Segment secondary>
            <Button color="green" content="Save Changes" labelPosition="left" icon="check" onClick={this.handleSubmit} loading={!!shiftsLoading} disabled={!!shiftsLoading} />
            <Button color="red" content="Delete Shift" labelPosition="right" icon="trash alternate" onClick={this.showConfirmDelete} disabled={!!shiftsLoading} />
          </Segment>
        </Segment.Group>

        </React.Fragment>

        ) : <LoadingSpinner message="Updating your data..." /> }
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  employeesErrors: selectEmployeesErrors,
  currentEmployees: selectCurrentEmployees,
  employeesLoading: selectEmployeesLoading,
  shiftsErrors: selectShiftsErrors,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  deleteShiftStart: ({ currentCompany, currentShifts, shiftId }) => 
    dispatch(deleteShiftStart({ currentCompany, currentShifts, shiftId })),
    updateShiftStart: ({ shiftId, shiftData, currentCompany }) => 
    dispatch(updateShiftStart({ shiftId, shiftData, currentCompany })),
  updateShiftFailure: (error) => dispatch(updateShiftFailure(error)),
  getEmployeesStart: ({ currentCompany }) => dispatch(getEmployeesStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftEdit);