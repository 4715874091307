import TransactionsActionTypes from './transactions.types';
import EmployeesActionTypes from '../employees/employees.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentTransactions: {},
  selectedTransactions: {}
}

const transactionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeesActionTypes.UPDATE_EMPLOYEE_SUCCESS: {
      const prevTransactions = { ...state.currentTransactions };
      let newTransactions = prevTransactions;
      const transactionAssignment = action.payload.employeeData.transactionAssignment;
      if (transactionAssignment !== "Unassigned" && !prevTransactions[transactionAssignment].assignedEmployees.includes(action.payload.employeeId)) {
        newTransactions[transactionAssignment].assignedEmployees = [...prevTransactions[transactionAssignment].assignedEmployees, action.payload.employeeId];
      }
      return {
        ...state,
        currentTransactions: newTransactions
      }
    }
    case EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS:
    {
      const prevTransactions = { ...state.currentTransactions };
      let newTransactions = prevTransactions;
      const transactionAssignment = action.payload.newEmployeeData.transactionAssignment;
      if (transactionAssignment !== "Unassigned" && !prevTransactions[transactionAssignment].assignedEmployees.includes(action.payload.newEmployeeId)) {
        newTransactions[transactionAssignment].assignedEmployees = [...prevTransactions[transactionAssignment].assignedEmployees, action.payload.newEmployeeId];
      }
      return {
        ...state,
        currentTransactions: newTransactions
      }
    }
    case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS: {
      const prevTransactions = { ...state.currentTransactions };
      let newTransactions = prevTransactions;
      Object.keys(newTransactions).forEach(transactionId => {
        if(prevTransactions[transactionId].assignedEmployees.includes(action.payload.removedId)) {
          newTransactions[transactionId].assignedEmployees = newTransactions[transactionId].assignedEmployees.filter(id => id !== action.payload.removedId)
        }
      });
      return {
        ...state,
        currentTransactions: {
          ...newTransactions
        }
      }
    }
    case TransactionsActionTypes.DELETE_TRANSACTION_START:
    case TransactionsActionTypes.ADD_TRANSACTION_START:
    case TransactionsActionTypes.GET_TRANSACTIONS_START:
    case TransactionsActionTypes.UPDATE_TRANSACTION_START:
    case TransactionsActionTypes.GET_DAY_TRANSACTIONS_START:
      return {
        ...state,
        loading: true
      }
    case TransactionsActionTypes.UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case TransactionsActionTypes.ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        currentTransactions: {
          ...state.currentTransactions,
          ...action.payload
        },
        loading: false,
        error: null
      }
    case TransactionsActionTypes.DELETE_TRANSACTION_SUCCESS:
      const prevTransactions = { ...state.currentTransactions };
      let newTransactions = { ...prevTransactions };
      delete newTransactions[action.payload.removedId];
      return {
        ...state,
        currentTransactions: newTransactions,
        loading: false,
        error: null
      }
    case TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        currentTransactions: action.payload.currentTransactions,
        loading: false,
        error: null
      }
    case TransactionsActionTypes.GET_DAY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        selectedTransactions: action.payload.selectedTransactions,
        loading: false,
        error: null
      }
    case TransactionsActionTypes.UPDATE_TRANSACTION_FAILURE:
    case TransactionsActionTypes.DELETE_TRANSACTION_FAILURE:
    case TransactionsActionTypes.ADD_TRANSACTION_FAILURE:
    case TransactionsActionTypes.GET_TRANSACTIONS_FAILURE:
    case TransactionsActionTypes.GET_DAY_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong. Refresh and try again, or contact team@perlstreet.com",
          error: action.payload
        }
      }
    default:
      return state;
  }
}

export default transactionsReducer;