import ModelsActionTypes from './models.types';

// Add model

export const addModelStart = ({ publicToken, models, institution, linkSessionId }) => ({
  type: ModelsActionTypes.ADD_MODEL_START,
  payload: {
    publicToken,
    models,
    institution,
    linkSessionId
  }
});

export const addModelSuccess = (newModelObject) => ({
  type: ModelsActionTypes.ADD_MODEL_SUCCESS,
  payload: newModelObject
});

export const addModelFailure = error => ({
  type: ModelsActionTypes.ADD_MODEL_FAILURE,
  payload: error
});

// Get models

export const getModelsStart = () => ({
  type: ModelsActionTypes.GET_MODELS_START
});

export const getModelsSuccess = (currentModels) => ({
  type: ModelsActionTypes.GET_MODELS_SUCCESS,
  payload: {
    currentModels
  }
});

export const getModelsFailure = error => ({
  type: ModelsActionTypes.GET_MODELS_FAILURE,
  payload: error
});

// Update MODEL

export const updateModelStart = ({ modelId, modelData, currentCompany }) => ({
  type: ModelsActionTypes.UPDATE_MODEL_START,
  payload: {
    modelId,
    modelData,
    currentCompany
  }
});

export const updateModelSuccess = (modelData) => ({
  type: ModelsActionTypes.UPDATE_MODEL_SUCCESS,
  payload: {
    modelData
  }
});

export const updateModelFailure = error => ({
  type: ModelsActionTypes.UPDATE_MODEL_FAILURE,
  payload: error
});

// Delete model

export const deleteModelStart = (modelId) => ({
  type: ModelsActionTypes.DELETE_MODEL_START,
  payload: {
    modelId
  }
});

export const deleteModelSuccess = (removedId) => ({
  type: ModelsActionTypes.DELETE_MODEL_SUCCESS,
  payload: {
    removedId
  }
});

export const deleteModelFailure = error => ({
  type: ModelsActionTypes.DELETE_MODEL_FAILURE,
  payload: error
});

// Update employee model assignment

export const updateEmployeeModelStart = ({ employeeId, modelId }) => ({
  type: ModelsActionTypes.UPDATE_EMPLOYEE_MODEL_START,
  payload: {
    employeeId,
    modelId
  }
});

export const updateEmployeeModelSuccess = ({ employeeId, modelId }) => ({
  type: ModelsActionTypes.UPDATE_EMPLOYEE_MODEL_SUCCESS,
  payload: {
    employeeId,
    modelId
  }
});

export const updateEmployeeModelFailure = error => ({
  type: ModelsActionTypes.UPDATE_EMPLOYEE_MODEL_FAILURE,
  payload: error
});

// Get models on date

export const getModelsOnDateStart = (selectedDate) => ({
  type: ModelsActionTypes.GET_DAY_MODELS_START,
  payload: {
    selectedDate
  }
});

export const getModelsOnDateSuccess = (selectedModels) => ({
  type: ModelsActionTypes.GET_DAY_MODELS_SUCCESS,
  payload: {
    selectedModels
  }
});

export const getModelsOnDateFailure = error => ({
  type: ModelsActionTypes.GET_DAY_MODELS_FAILURE,
  payload: error
});
