import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Form, Grid, Header, Message, Segment, Image } from 'semantic-ui-react';

import BrandColors from '../../utils/brandColors';
import { selectUserErrors, selectUserLoading } from '../../redux/user/user.selectors';
import { signUpStart, signUpFailure } from '../../redux/user/user.actions';
import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';
import './SignUpPage.styles.css';

class SignUpPage extends React.Component { 
  state = {
    email: '',
    password: '',
    confirmPassword: ''
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { signUpStart, signUpFailure } = this.props;
    const { email, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      signUpFailure({
        code: "custom/failed-password-confirmation",
        message: "The password and password confirmation fields don't match!"
      })
      return;
    }

    signUpStart({ email, password });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    // const { googleSignInStart } = this.props;
    const { userErrors, userLoading } = this.props;
    const { email, password, confirmPassword } = this.state;

    return (
      <div className="signupBackground">
        <div className="signupBackgroundOverlay">
          <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' padded>
            <Grid.Column style={{ maxWidth: 450 }}>

              <Link to="/">
                <Header as='h1' inverted>
                  <Image src={"/logos/logo_ps_w_20x.png"} style={{ margin: 0, height: 'auto', width: 'auto', maxWidth: '250px' }} />
                </Header>
              </Link>
              
              <Segment.Group stacked>
                {/* <Segment>
                  <Header as='h2' color={BrandColors.SEMANTIC_COLOR_PRIMARY} textAlign='center'>
                    <Icon name="user circle" /> Create an Account
                  </Header> 
                </Segment> */}
                <Segment>
                  <Form size='large' onSubmit={this.handleSubmit}>
                    {/* <p style={{fontWeight: 'bold'}}>It's time for you to grab a slice ...</p> */}
                    {/* <Form.Input 
                      fluid 
                      icon='user' 
                      iconPosition='left' 
                      placeholder='Enter a public username' 
                      name="displayName" 
                      value={displayName}
                      onChange={this.handleChange} 
                    /> */}
                    <Form.Input 
                      fluid 
                      icon='user' 
                      iconPosition='left' 
                      placeholder='Your email address' 
                      name="email" 
                      value={email}
                      onChange={this.handleChange} 
                    />
                    <Form.Input
                      fluid
                      icon='lock'
                      iconPosition='left'
                      placeholder='Create a password'
                      type='password'
                      name="password" 
                      value={password}
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      fluid
                      icon='repeat'
                      iconPosition='left'
                      placeholder='Confirm your password'
                      type='password'
                      name="confirmPassword" 
                      value={confirmPassword}
                      onChange={this.handleChange}
                    />

                    {/* <p style={{fontWeight: 'bold'}}>... and <span style={{color: BrandColors.HEX_COLOR_PRIMARY}}>carve out your new destiny.</span></p> */}

                    <Button type="submit" color={BrandColors.SEMANTIC_COLOR_PRIMARY} fluid size='large' loading={!!userLoading} disabled={!!userLoading}>
                      Sign Up
                    </Button>

                  </Form>

                  {/* <Divider horizontal>SIGN IN WITH GOOGLE</Divider> */}
                  {/* <Button type='button' onClick={googleSignInStart} className="signInWithGoogleButton" icon="google" content="Sign in with Google" labelPosition="left"/> */}

                </Segment>
              </Segment.Group>
              
              <Message info>
                Already have an account? <Link to="/login" className="slightLeftMargin">Log In Here</Link>
              </Message>
              
              {
                userErrors ? (
                  <Message error>
                    <Message.Header>{userErrors.title}</Message.Header>
                    { renderCustomizedErrorMessage(userErrors.error.code, userErrors.error.message) }
                  </Message>
                ) : null
              }
              
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userErrors: selectUserErrors,
  userLoading: selectUserLoading
});

const mapDispatchToProps = dispatch => ({
  signUpStart: (userCredentials) => dispatch(signUpStart(userCredentials)),
  signUpFailure: (error) => dispatch(signUpFailure(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);