import React from 'react';
import { Feed, Icon, Card, Label, Embed } from 'semantic-ui-react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

class ModelReviewEvent extends React.Component {

  embedLoomVideo = (videoID) => (
    <Embed
      source={null}
      iframe={{
        title: `ModelReviewLoom-${videoID}`,
        src: `https://www.loom.com/embed/${videoID}`,
        frameBorder: "0",
        webkitallowfullscreen: "true",
        mozallowfullscreen: "true",
        allowFullScreen: true,
        style: {
          // padding: 10,
          position: 'absolute',
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%'
        }
      }}
      placeholder='https://react.semantic-ui.com/images/image-16by9.png'
    />
  );

  displayEventHeader = ({
    updateType,
    updateUserName
  } = {}) => {
    if (updateType === "model_update") {
      return <Feed.Summary><Feed.User>{updateUserName}</Feed.User>{` updated the financial model`}</Feed.Summary>;
    } else if (updateType === "review_meeting") {
      return <Feed.Summary>{`Model reviewed with `}<Feed.User>{updateUserName}</Feed.User></Feed.Summary>;
    } else if (updateType === "initial_model_upload") {
      return <Feed.Summary><Feed.User>{updateUserName}</Feed.User>{` uploaded a financial model`}</Feed.Summary>;
    } else if (updateType === "generic_upload") {
      return <Feed.Summary><Feed.User>{updateUserName}</Feed.User>{` uploaded a file`}</Feed.Summary>;
    } else if (updateType === "generic_update") {
      return <Feed.Summary><Feed.User>{updateUserName}</Feed.User>{` updated a file`}</Feed.Summary>;
    } else {
      return <Feed.Summary><Feed.User>{updateUserName}</Feed.User>{` updated a file`}</Feed.Summary>;
    }
  }

  displayEvent = ({
    eventId = null,
    eventMoment = null,
    updateUserName = "Anonymous",
    updateUserUrl = null,
    updateType = null,
    clientReviewPending = false,
    loomVideoId = null,
    fileDownloadLink = null,
    fileDownloadName = null,
    eventNotes = []
  } = {}) => {
    let notesTitle = "Version Notes";
    if (updateType === "review_meeting") {
      notesTitle = "Meeting Notes";
    }
    if (!eventId) {
      console.error("No event ID provided!");
      eventId = uuidv4();
    }
    return (
      <Feed.Event key={eventId}>
        {updateType === "review_meeting" ? (
          <Feed.Label icon="calendar check" />
        ) : (
          updateUserUrl ? (
            <Feed.Label image={updateUserUrl} />
          ) : (
            <Feed.Label icon="calendar" />
          )
        )}
        <Feed.Content>
          {eventMoment ? (
            <Feed.Date>{moment(eventMoment).format('MMMM Do YYYY, h:mm a')}</Feed.Date>
          ) : null}
          {this.displayEventHeader({
            updateType,
            updateUserName
          })}
          {clientReviewPending ? (
            <Feed.Meta><Label content="Pending Client Review" icon="exclamation circle" color="orange"/></Feed.Meta>
          ) : null}
          {loomVideoId ? (
          <Feed.Extra>
            <Card>
              {this.embedLoomVideo(loomVideoId)}
            </Card>
          </Feed.Extra>
          ) : null}
          {fileDownloadLink ? (
            <Feed.Extra text style={{ marginTop: 0 }}>
              <Feed.Meta>
                <Feed.Like as="a" href={fileDownloadLink}>
                  <Icon name='download' />{fileDownloadName || "Download File"}
                </Feed.Like>
              </Feed.Meta>
            </Feed.Extra>
          ) : null}
          { eventNotes ? (
            eventNotes.length ? (
              <Feed.Extra text>
                {notesTitle}:
                <ul>
                  {eventNotes.map((note, note_i) => <li key={`${eventId}-${note_i}`}>{note}</li>)}
                </ul>
              </Feed.Extra>
            ) : null
          ) : null}
        </Feed.Content>
      </Feed.Event>
    );
  }

  render() {
    return (
      this.displayEvent({...this.props})
    );
  }
}

export default ModelReviewEvent;