import TransactionsActionTypes from './transactions.types';

// Add transaction

export const addTransactionStart = ({ publicToken, transactions, institution, linkSessionId }) => ({
  type: TransactionsActionTypes.ADD_TRANSACTION_START,
  payload: {
    publicToken,
    transactions,
    institution,
    linkSessionId
  }
});

export const addTransactionSuccess = (newTransactionObject) => ({
  type: TransactionsActionTypes.ADD_TRANSACTION_SUCCESS,
  payload: newTransactionObject
});

export const addTransactionFailure = error => ({
  type: TransactionsActionTypes.ADD_TRANSACTION_FAILURE,
  payload: error
});

// Get transactions

export const getTransactionsStart = () => ({
  type: TransactionsActionTypes.GET_TRANSACTIONS_START
});

export const getTransactionsSuccess = (currentTransactions) => ({
  type: TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS,
  payload: {
    currentTransactions
  }
});

export const getTransactionsFailure = error => ({
  type: TransactionsActionTypes.GET_TRANSACTIONS_FAILURE,
  payload: error
});

// Update TRANSACTION

export const updateTransactionStart = ({ transactionId, transactionData, currentCompany }) => ({
  type: TransactionsActionTypes.UPDATE_TRANSACTION_START,
  payload: {
    transactionId,
    transactionData,
    currentCompany
  }
});

export const updateTransactionSuccess = (transactionData) => ({
  type: TransactionsActionTypes.UPDATE_TRANSACTION_SUCCESS,
  payload: {
    transactionData
  }
});

export const updateTransactionFailure = error => ({
  type: TransactionsActionTypes.UPDATE_TRANSACTION_FAILURE,
  payload: error
});

// Delete transaction

export const deleteTransactionStart = (transactionId) => ({
  type: TransactionsActionTypes.DELETE_TRANSACTION_START,
  payload: {
    transactionId
  }
});

export const deleteTransactionSuccess = (removedId) => ({
  type: TransactionsActionTypes.DELETE_TRANSACTION_SUCCESS,
  payload: {
    removedId
  }
});

export const deleteTransactionFailure = error => ({
  type: TransactionsActionTypes.DELETE_TRANSACTION_FAILURE,
  payload: error
});

// Update employee transaction assignment

export const updateEmployeeTransactionStart = ({ employeeId, transactionId }) => ({
  type: TransactionsActionTypes.UPDATE_EMPLOYEE_TRANSACTION_START,
  payload: {
    employeeId,
    transactionId
  }
});

export const updateEmployeeTransactionSuccess = ({ employeeId, transactionId }) => ({
  type: TransactionsActionTypes.UPDATE_EMPLOYEE_TRANSACTION_SUCCESS,
  payload: {
    employeeId,
    transactionId
  }
});

export const updateEmployeeTransactionFailure = error => ({
  type: TransactionsActionTypes.UPDATE_EMPLOYEE_TRANSACTION_FAILURE,
  payload: error
});

// Get transactions on date

export const getTransactionsOnDateStart = (selectedDate) => ({
  type: TransactionsActionTypes.GET_DAY_TRANSACTIONS_START,
  payload: {
    selectedDate
  }
});

export const getTransactionsOnDateSuccess = (selectedTransactions) => ({
  type: TransactionsActionTypes.GET_DAY_TRANSACTIONS_SUCCESS,
  payload: {
    selectedTransactions
  }
});

export const getTransactionsOnDateFailure = error => ({
  type: TransactionsActionTypes.GET_DAY_TRANSACTIONS_FAILURE,
  payload: error
});
