import UserActionTypes from './user.types';

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START
});

export const resetPasswordStart = (email) => ({
  type: UserActionTypes.RESET_USER_PASSWORD_START,
  payload: email
});

export const resetPasswordSuccess = () => ({
  type: UserActionTypes.RESET_USER_PASSWORD_SUCCESS
});

export const resetPasswordFailure = (error) => ({
  type: UserActionTypes.RESET_USER_PASSWORD_FAILURE,
  payload: error
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials
});

export const signUpSuccess = (userData) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: userData
});

export const signUpFailure = error => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error
});

export const createInitialUserProfileStart = ({ currentUser, firstName, lastName, phoneNumber }) => ({
  type: UserActionTypes.CREATE_INITIAL_USERPROFILE_START,
  payload: {
    currentUser,
    firstName, 
    lastName, 
    phoneNumber
  }
});

export const createInitialUserProfileSuccess = ({ firstName, lastName, phoneNumber }) => ({
  type: UserActionTypes.CREATE_INITIAL_USERPROFILE_SUCCESS,
  payload: {
    firstName, 
    lastName, 
    phoneNumber
  }
});

export const createInitialUserProfileFailure = error => ({
  type: UserActionTypes.CREATE_INITIAL_USERPROFILE_FAILURE,
  payload: error
});

export const createInitialCompanyStart = ({ currentUser, companyName, companyCity, companyState, workforceSize }) => ({
  type: UserActionTypes.CREATE_INITIAL_COMPANY_START,
  payload: {
    currentUser,
    companyAttributes: {
      companyName, 
      companyCity, 
      companyState,
      workforceSize
    }
  }
});

export const createInitialCompanySuccess = (newCompanyId) => ({
  type: UserActionTypes.CREATE_INITIAL_COMPANY_SUCCESS,
  payload: newCompanyId
});

export const createInitialCompanyFailure = error => ({
  type: UserActionTypes.CREATE_INITIAL_COMPANY_FAILURE,
  payload: error
});