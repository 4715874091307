import React from 'react';
import { Segment, Container, Image, Header, Grid, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const FooterMenu = () => (
  <Segment inverted vertical style={{ padding: '5em 0em' }}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={6} textAlign="center">
            <Link to="/">
              <Header as='h4' inverted>
                <Image src={"/logos/logo_ps_w_20x.png"} style={{ margin: 0, width: 'auto', height: 'auto', maxWidth: '150px' }} />
              </Header>
            </Link>
            <p>
              © Perl Street {new Date().getFullYear()}
            </p>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='Our Company' />
            <List link inverted>
              <List.Item as={Link} to="/">Home</List.Item>
              <List.Item as={Link} to="/about">About Us</List.Item>
              <List.Item as={Link} to="/how-it-works">How it Works</List.Item>
              <List.Item as={Link} to="/faqs">FAQs</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='Learn More' />
            <List link inverted>
              <List.Item as={Link} to="/xaas-academy">XaaS Academy</List.Item>
              <List.Item as={Link} to="/how-it-works">Why Debt</List.Item>
              <List.Item as={Link} to="/how-it-works#off-balance-sheet">Off-Balance Sheet</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
);

export default FooterMenu;