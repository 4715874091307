const ModelsActionTypes = {
  ADD_MODEL_START: 'ADD_MODEL_START',
  ADD_MODEL_SUCCESS: 'ADD_MODEL_SUCCESS',
  ADD_MODEL_FAILURE: 'ADD_MODEL_FAILURE',

  GET_MODELS_START: 'GET_MODELS_START',
  GET_MODELS_SUCCESS: 'GET_MODELS_SUCCESS',
  GET_MODELS_FAILURE: 'GET_MODELS_FAILURE',

  UPDATE_MODEL_START: 'UPDATE_MODEL_START',
  UPDATE_MODEL_SUCCESS: 'UPDATE_MODEL_SUCCESS',
  UPDATE_MODEL_FAILURE: 'UPDATE_MODEL_FAILURE',

  DELETE_MODEL_START: 'DELETE_MODEL_START',
  DELETE_MODEL_SUCCESS: 'DELETE_MODEL_SUCCESS',
  DELETE_MODEL_FAILURE: 'DELETE_MODEL_FAILURE',

  UPDATE_EMPLOYEE_MODEL_START: 'UPDATE_EMPLOYEE_MODEL_START',
  UPDATE_EMPLOYEE_MODEL_SUCCESS: 'UPDATE_EMPLOYEE_MODEL_SUCCESS',
  UPDATE_EMPLOYEE_MODEL_FAILURE: 'UPDATE_EMPLOYEE_MODEL_FAILURE',

  GET_DAY_MODELS_START: 'GET_DAY_MODELS_START',
  GET_DAY_MODELS_SUCCESS: 'GET_DAY_MODELS_SUCCESS',
  GET_DAY_MODELS_FAILURE: 'GET_DAY_MODELS_FAILURE',
};

export default ModelsActionTypes;