import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  List,
  Card
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './WhyDebtPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const WhyDebtHeading = ({ mobile }) => (
  <div className="whyDebtPageOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        <Header
          as='h2'
          content={
          <React.Fragment>
            <span style={{ fontFamily: 'Lora, serif' }}>But <span style={{color: "rgb(0, 195, 255)" }}>Am I Ready</span> for Debt?</span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        />
        <Header
          as='h2'
          content={
          <React.Fragment>
            Capital intensive companies are generally a poor match for most venture capital. <strong>Even if you're not ready, there are good reasons to get started early.</strong>
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        />

        {/* <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
          style={{
            marginTop: '1em'
          }}
        >
          Learn More About Debt
          <Icon name='right chevron' />
        </Button> */}
      </Container>
    </Segment>
  </div>
)

WhyDebtHeading.propTypes = {
  mobile: PropTypes.bool,
}

const WhyDebtPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <WhyDebtHeading {...props} />

      <Segment style={{ padding: '0em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Grid.Row>
            <Grid.Column>
              <Divider
                as='h4'
                className='header'
                horizontal
                style={{ margin: '3em 0em', textTransform: 'uppercase' }}
              >
                <p style={{ color: "#4183c4" }}>NOW LET'S TALK ABOUT DEBT</p>
              </Divider>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column floated='left' width={7}>
              <Header as='h3' style={{ fontSize: '2.5em' }}>
                <Header.Content>
                Is <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>raising debt</span> in general really a good idea for us?
                </Header.Content>
              </Header>
              <p className="hugeText">Of course it always depends on your situation - but the short answer is often yes, <span style={{ textDecoration: 'underline' }}>especially for capital intensive companies</span>.</p>
              <p className="hugeText"><strong>Capital intensive companies are generally a poor match for most venture capital</strong>, which requires a high rate of return that's a lot easier to achieve without physical assets holding you down.</p>
              <p className="hugeText">Leveraging with debt allows for a higher return on equity, and more options for your company. <i>There are also creative ways to remove owned assets from the equation entirely!</i></p>
            </Grid.Column>
            <Grid.Column width={9} textAlign="center">
              <Image src={'media/illustrations/PlatformROEMath.png'} centered />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header as='h3' style={{ fontSize: '2.5em' }} textAlign="center">
                <Header.Subheader>
                  Raising debt alongside your equity round allows you to maximize the bang for your buck.<br/>
                  It's generally better to utilize equity capital for higher ROI activities rather than inventory and materials.
                </Header.Subheader>
              </Header>
              <Image src={'media/illustrations/CapitalAllocationExample.png'} centered style={{ width: '100%', maxWidth: '500px' }} />
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Segment>

      <Segment vertical className="white">
        <Container text textAlign="center">

          <Divider
            as='h4'
            className='header'
            horizontal
            style={{ margin: '3em 0em', textTransform: 'uppercase' }}
          >
            <p style={{ color: "#4183c4" }}>GET STARTED WITH US</p>
          </Divider>

          <Header as='h3' style={{ fontSize: '2em' }}>
            <i>Are you ready to unlock sustainable capital, increase your return on equity, and save time on fundraising<span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>?</span></i>
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            Then what's stopping you from getting started?
          </p>
          <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='large' icon="arrow right" labelPosition="right" content="Alright, let's do it" />

          <Divider
            as='h4'
            className='header'
            horizontal
            style={{ margin: '3em 0em', textTransform: 'uppercase' }}
          >
            <p style={{ color: "#4183c4" }}>AND FOR EVERYONE ELSE</p>
          </Divider>

          <Header as='h3' style={{ fontSize: '2em' }}>
            Any questions?
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            Contact us at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to hear more.
          </p>

          <Divider hidden />
          <Divider hidden />
          <Divider hidden />

        </Container>
      </Segment>

      <Segment inverted vertical style={{ padding: '5em 0em' }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              {/* <Grid.Column width={3}>
                <Header inverted as='h4' content='About' />
                <List link inverted>
                  <List.Item as='a'>Sitemap</List.Item>
                  <List.Item as='a'>Contact Us</List.Item>
                  <List.Item as='a'>Religious Ceremonies</List.Item>
                  <List.Item as='a'>Gazebo Plans</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Services' />
                <List link inverted>
                  <List.Item as='a'>Banana Pre-Order</List.Item>
                  <List.Item as='a'>DNA FAQ</List.Item>
                  <List.Item as='a'>How To Access</List.Item>
                  <List.Item as='a'>Favorite X-Men</List.Item>
                </List>
              </Grid.Column> */}
              <Grid.Column width={16} textAlign="center">
                <Link to="/">
                  <Header as='h4' inverted>
                    <Image src={"/logos/logo_ps_w_20x.png"} style={{ margin: 0, width: 'auto', height: 'auto', maxWidth: '150px' }} />
                  </Header>
                </Link>
                <p>
                  © Perl Street {new Date().getFullYear()}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </AppContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(WhyDebtPage);