import React from 'react';
import { Container, Header, Segment, Button, List } from 'semantic-ui-react';

class OrganizationsList extends React.Component {

  renderAccountSublist = (subAccountId) => {
    const accountList = {
      holdcoAccounts: {
        "account-1": {
          accountName: "First Republic Checking"
        },
        "account-2": {
          accountName: "Brex Card"
        }
      },
      speAccounts: {
        "account-1": {
          accountName: "Silicon Valley Bank Checking"
        },
        "account-2": {
          accountName: "American Express Gold Card"
        }
      }
    }
    const subAccounts = accountList[subAccountId];
    const { setCurrentView } = this.props; 
    const accountIds = Object.keys(subAccounts);
    return (
      <List divided verticalAlign='middle' animated size="large">
        { accountIds.map(accountId => (
          <List.Item key={`${subAccountId}-${accountId}`}>
            <List.Content floated='right'>
              <Button onClick={() => setCurrentView("AccountEdit", { accountId })}>Manage</Button>
            </List.Content>
            {/* <List.Icon name="building" size="large"/> */}
            <List.Content>
              {subAccounts[accountId].accountName}
            </List.Content>
          </List.Item>
        )) }
      </List>
    );
  }

  render() {
    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Organizations</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>All Organizations</Header.Content>
            </Header>
          </Segment>

          <Segment loading={false}>
            <Header as="h2"><Header.Subheader>HoldCo Accounts</Header.Subheader></Header>
            {this.renderAccountSublist("holdcoAccounts")}

            <Header as="h2"><Header.Subheader>SPE Accounts</Header.Subheader></Header>
            {this.renderAccountSublist("speAccounts")}
          </Segment>
          <Segment secondary>
            <Button 
              color="blue" content="Add New Account" labelPosition="left" icon="add" 
              // onClick={() => setCurrentView("EmployeeCreate")}
            />
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

export default OrganizationsList;