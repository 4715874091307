import React from 'react';
import { Segment, Container, Divider, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import BrandColors from '../../utils/brandColors';

const FooterCTA = () => (
  <Segment vertical className="white">
    <Container text textAlign="center">

      <Divider
        as='h4'
        className='header'
        horizontal
        style={{ margin: '3em 0em', textTransform: 'uppercase' }}
      >
        <p style={{ color: "#4183c4" }}>GET STARTED WITH US</p>
      </Divider>

      <Header as='h3' style={{ fontSize: '2em' }}>
        <i>Are you ready to unlock sustainable capital, increase your return on equity, and save time on fundraising<span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>?</span></i>
      </Header>
      <p style={{ fontSize: '1.33em' }}>
        Then what's stopping you from getting started?
      </p>
      <a href="https://app.perlstreet.com/register">
      <Button 
        // as={Link} to="/signup" 
        color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='large' icon="arrow right" 
        labelPosition="right" content="Alright, let's do it" 
      />
      </a>

      <Divider
        as='h4'
        className='header'
        horizontal
        style={{ margin: '3em 0em', textTransform: 'uppercase' }}
      >
        <p style={{ color: "#4183c4" }}>AND FOR EVERYONE ELSE</p>
      </Divider>

      <Header as='h3' style={{ fontSize: '2em' }}>
        Any questions?
      </Header>
      <p style={{ fontSize: '1.33em' }}>
        Contact us at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to hear more.
      </p>

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />

    </Container>
  </Segment>
);

export default FooterCTA;