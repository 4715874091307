import { all, call } from 'redux-saga/effects';

import { userSagas } from './user/user.sagas';
import { onboardingSagas } from './onboarding/onboarding.sagas';
import { companySagas } from './company/company.sagas';
import { accountsSagas } from './accounts/accounts.sagas';
import { transactionsSagas } from './transactions/transactions.sagas';
import { modelsSagas } from './models/models.sagas';

import { employeesSagas } from './employees/employees.sagas';
import { shiftsSagas } from './shifts/shifts.sagas';

export default function* rootSaga() {
  // Initializes each saga on separate task streams instead of yielding each one
  yield all([
    call(userSagas),
    call(onboardingSagas),
    call(companySagas),
    call(accountsSagas),
    call(transactionsSagas),
    call(modelsSagas),
    
    call(employeesSagas),
    call(shiftsSagas)
  ]);
};