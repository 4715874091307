import React from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from "react-plotly.js/factory";
import moment from 'moment-timezone';

const Plot = createPlotlyComponent(Plotly);
// import { ResponsiveBar } from '@nivo/bar'
// import './chart.css'

class Chart extends React.Component {

  clickHandler = (data) => {
    const { handleClick } = this.props;
    if (!handleClick) {
      console.log("No click handler defined");
    } else {
      handleClick(data);
    }
  }

  renderPlot = () => {
    const { historicalAccountBalances } = this.props;
    const { historicalAccountBalancesRet, historicalAggregatedBalancesRet } = historicalAccountBalances;
    if (!historicalAccountBalancesRet) return null;
    if (!Object.keys(historicalAccountBalancesRet).length) return null;

    // Transform account balances into plotting object
    // const posColor = '#00a65a';
    // const negColor = '#dd4b39';
    let numGreens = 0;
    let numReds = 0;
    const greens = [ "#dcedc8", "#aed581", "#8bc34a", "#689f38", "#c0ca33", "#33691e", "#00a65a" ].reverse();
    const reds = [ "#f2d7d5", "#d98880", "#cd6155", "#c0392b", "#a93226", "#922b21", "#dd4b39" ].reverse();

    const plotAssetBalances = ["asset"].map(key => {
      const balObj = historicalAggregatedBalancesRet[key];
      const transformedVals = balObj.accountAggType === "asset" ? balObj.balancesY.map(d => d*(1 - Math.random()*0.3)) : balObj.balancesY.map(d => -d(1 - Math.random()*0.3));
      const colorSel = balObj.accountAggType === "asset" ? greens[numGreens % greens.length] : reds[numReds % reds.length];
      balObj.accountAggType === "asset" ? numGreens += 1 : numReds += 1;
      return ({
        ...balObj,
        name: balObj.name || balObj.accountAggType,
        x: balObj.balancesX,
        y: transformedVals,
        marker: { 
          color: colorSel, 
          line: { color: 'white', width: 1 }
        },
        type: 'bar'
      })
    })

    const plotAssetProjections = ["asset"].map(key => {
      // Extract the balance values
      const balObj = historicalAggregatedBalancesRet[key];
      const transformedVals = balObj.accountAggType === "asset" ? balObj.projectionsY.map(d => d*(1 - Math.random()*0.3)) : balObj.projectionsY.map(d => -d*(1 - Math.random()*0.3));
      
      // Backfill projection values
      const transformedPriors = balObj.accountAggType === "asset" ? balObj.balancesY.map(d => d*(1 - Math.random()*0.3)) : balObj.balancesY.map(d => -d*(1 - Math.random()*0.3));
      
      // Colorize object
      const colorSel = balObj.accountAggType === "asset" ? greens[numGreens % greens.length] : reds[numReds % reds.length];
      balObj.accountAggType === "asset" ? numGreens += 1 : numReds += 1;

      // Return graph object
      return ({
        ...balObj,
        name: `Projections`,
        x: [...balObj.balancesX, ...balObj.projectionsX],
        y: [...transformedPriors, ...transformedVals],
        marker: { 
          color: colorSel, 
          line: { color: 'white', width: 1 },
          size: 6
        },
        line: { 
          color: colorSel, 
          width: 3
        },
        mode: 'lines+markers'
      })
    })

    // const plotAccountBalances = Object.keys(historicalAggregatedBalancesRet).map(key => {
    //   const balObj = historicalAggregatedBalancesRet[key];
    //   const transformedVals = balObj.accountAggType === "asset" ? balObj.balancesY : balObj.balancesY.map(d => -d);
    //   const colorSel = balObj.accountAggType === "asset" ? greens[numGreens % greens.length] : reds[numReds % reds.length];
    //   balObj.accountAggType === "asset" ? numGreens += 1 : numReds += 1;
    //   return ({
    //     ...balObj,
    //     name: balObj.name || balObj.accountAggType,
    //     x: balObj.balancesX,
    //     y: transformedVals,
    //     marker: { 
    //       color: colorSel, 
    //       line: { color: 'white', width: 1 }
    //     },
    //     type: 'bar'
    //   })
    // })

    
    // l_item.type = 'bar';

    // let trace1 = {
    //   x: ["2020-03-01","2020-03-02","2020-03-03"],
    //   y: [20, -14, 23],
    //   name: 'SF Zoo',
    //   type: 'bar'
    // };

    // let trace2 = {
    //   x: ["2020-03-01","2020-03-02","2020-03-03"],
    //   y: [25, 15, 10],
    //   name: 'SF Zoo',
    //   type: 'bar'
    // };

    // Define plot layout
    let plotLayout = {
      title: null,
      showlegend: false,
      paper_bgcolor: 'rgba(0,0,0,0)', plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        tickfont: {
          size: 14,
          color: 'rgb(107, 107, 107)'
        },
        fixedrange: true
      },
      yaxis: {
        title: null,
        titlefont: {
          size: 16,
          color: 'rgb(107, 107, 107)'
        },
        tickfont: {
          size: 14,
          color: 'rgb(107, 107, 107)'
        },
        fixedrange: true
      },
      legend: {
        x: 0,
        y: 1.0,
        bgcolor: 'rgba(255, 255, 255, 0)',
        bordercolor: 'rgba(255, 255, 255, 0)'
      },
      margin: {
        l: 50,
        r: 25,
        b: 50,
        t: 25,
        pad: 4
      },
      barmode: 'relative',
      bargap: 0.15,
      bargroupgap: 0.1,
      autosize: true
    };

    return (
      <div style={{height: '300px', width: '100%'}}>
        <Plot
          data={[...plotAssetBalances, ...plotAssetProjections]}
          layout={plotLayout}
          useResizeHandler={true}
          style={{width: "100%", height: "100%"}}
          config={{
            // staticPlot: true,
            displayModeBar: false
          }}
          onClick={(data) => this.clickHandler(data)}
        />
      </div>
    );
  }

    render() {
      return (
        this.renderPlot()
      )
    }
}

export default Chart;

// <div className="chart">
          //   { (!!historicalAccountBalances.aggBalancesRet && !!historicalAccountBalances.keys) ? (
          //     <ResponsiveBar
          //       data={historicalAccountBalances.aggBalancesRet}
          //       // keys={['depository','blah1','blah2','credit']}
          //       keys={historicalAccountBalances.keys}
          //       indexBy="dateKey"
          //       colors={['#97e3d5', '#61cdbb', '#f47560', '#e25c3b']}
          //       margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
          //       padding={0.3}

          //       labelSkipWidth={12}
          //       labelSkipHeight={12}
          //       animate={true}
          //       motionStiffness={90}
          //       motionDamping={15}

          //       minValue="auto"
          //       maxValue="auto"

          //       xScale={{
          //         type: 'time',
          //         format: '%Y-%m-%d',
          //         useUTC: false,
          //         precision: 'day',
          //       }}
          //       xFormat="time:%Y-%m-%d"
          //     />
          //   ) : "Loading" }
          // </div>