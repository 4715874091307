import React from 'react';
import { Container, Segment, Header, Button, List, Icon, Label, Checkbox } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';

class SurveyList extends React.Component {
  componentDidMount() {
    const { currentCompany, getShiftsStart } = this.props;
    getShiftsStart({ currentCompany });
  }

  renderShiftSublist = (shiftList) => {
    const { setCurrentView } = this.props;
    const shiftIds = Object.keys(shiftList);
    return (
      <List divided relaxed verticalAlign='middle' animated size="large">
        { shiftIds.map(shiftId => (
          <List.Item key={shiftId}>
            <List.Content floated='right'>
              {/* <Button onClick={() => setCurrentView("ShiftEdit", { shiftId })}>Manage</Button> */}
              <Checkbox
                checked={shiftList[shiftId].shiftSendAuto} 
                disabled
                toggle 
              />
            </List.Content>
            <List.Icon name="tasks" size="large"/>
            <List.Content>
              <List.Header>{shiftList[shiftId].shiftName}
                <Label size="mini" style={{ marginLeft: '6px' }} image basic>
                  <Icon name='stopwatch' />
                  Starts
                  <Label.Detail>{moment(shiftList[shiftId].shiftStart,"HH:mm").tz(shiftList[shiftId].shiftTimezone).format("h:mm A z")}</Label.Detail>
                </Label>
                { shiftList[shiftId].shiftSendAuto ? (
                  <Label size="mini" color="orange" style={{ marginLeft: '6px' }} image>
                    <Icon name='send' />
                    Autosend
                    <Label.Detail>{moment(shiftList[shiftId].shiftStart,"HH:mm").tz(shiftList[shiftId].shiftTimezone).subtract(+shiftList[shiftId].shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                  </Label>
                ) : null }
              </List.Header>
              <List.Description>{`${shiftList[shiftId].assignedEmployees.length || "No"} assigned employees`}</List.Description>
            </List.Content>
          </List.Item>
        )) }
      </List>
    );
  }

  renderShiftList = () => {
    const { currentShifts } = this.props;
    if (!currentShifts) {
      return null;
    } else {
      const currentShiftIds = Object.keys(currentShifts);
      return (
        <React.Fragment>
        {currentShiftIds.length ? (
          this.renderShiftSublist(currentShifts)
        ) : null}
        </React.Fragment>
      );
    }
  }

  render() {
    const { setCurrentView, shiftsLoading } = this.props;

    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Surveys</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Schedule Surveys</Header.Content>
            </Header>
          </Segment>

          <Segment loading={shiftsLoading}>
            <Header>
              <Header.Content>COVID-19 Self-Screening SMS Survey <Label color="black">Automated</Label></Header.Content>
              <Header.Subheader>Enable automated surveys for each shift as needed</Header.Subheader>
            </Header>
            {this.renderShiftList()}
          </Segment>
          {/* <Segment secondary>
            <Button color="green" 
              content={
                <span>Schedule Surveys
                <Label circular className="buttonCircleNotification">1</Label>
                </span>
              } labelPosition="left" icon="check" />
          </Segment> */}
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);