import EmployeesActionTypes from './employees.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentEmployees: null
}

const employeesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeesActionTypes.DELETE_EMPLOYEE_START:
    case EmployeesActionTypes.ADD_EMPLOYEE_START:
    case EmployeesActionTypes.GET_EMPLOYEES_START:
    case EmployeesActionTypes.UPDATE_EMPLOYEE_START:
      return {
        ...state,
        loading: true
      }
    case EmployeesActionTypes.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentEmployees: {
          ...state.currentEmployees,
          [action.payload.newEmployeeId]: action.payload.newEmployeeData
        },
        loading: false,
        error: null
      }
    case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentEmployees: action.payload.employeeData,
        loading: false,
        error: null
      }
    case EmployeesActionTypes.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        currentEmployees: action.payload.currentEmployees,
        loading: false,
        error: null
      }
    case EmployeesActionTypes.UPDATE_EMPLOYEE_FAILURE:
    case EmployeesActionTypes.DELETE_EMPLOYEE_FAILURE:
    case EmployeesActionTypes.ADD_EMPLOYEE_FAILURE:
    case EmployeesActionTypes.GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong. Refresh the page and try again, or contact team@perlsteet.com",
          error: action.payload
        }
      }
    default:
      return state;
  }
}

export default employeesReducer;