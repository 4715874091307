import { takeLatest, put, all, call } from 'redux-saga/effects';

import UserActionTypes from './user.types';

import { 
  signInSuccess, signInFailure, 
  signOutSuccess, signOutFailure, 
  signUpSuccess, signUpFailure,
  resetPasswordSuccess, resetPasswordFailure,
  createInitialUserProfileSuccess, createInitialUserProfileFailure, 
  createInitialCompanySuccess, createInitialCompanyFailure
} from './user.actions';

import { setCurrentView } from '../view/view.actions';

import { setCurrentCompany } from '../company/company.actions';

import { 
  auth, googleProvider, 
  createUserProfileDocument, getCurrentUser, updateUserAttributes, 
  createInitialCompanyDocument 
} from '../../firebase/firebase.utils';

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(createUserProfileDocument, userAuth, additionalData);
    const userSnapshot = yield userRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle)
}

export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail)
}

export function* signInWithEmail({ payload: { email, password }}) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOut() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* signInAfterSignup({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* signUp({ payload: { email, password } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    yield put(signUpSuccess({ user, additionalData: { } }));
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignup);
}

export function* onResetPasswordStart() {
  yield takeLatest(UserActionTypes.RESET_USER_PASSWORD_START, resetUserPassword);
}

export function* resetUserPassword({ payload }) {
  try {
    const email = payload;
    yield auth.sendPasswordResetEmail(email);
    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export function* createInitialUserProfile({ payload: { currentUser, firstName, lastName, phoneNumber } }) {
  try {
    yield updateUserAttributes(currentUser, { firstName, lastName, phoneNumber });
    yield put(createInitialUserProfileSuccess({ firstName, lastName, phoneNumber }));
    yield put(setCurrentView("CreateInitialCompany"));
  } catch (error) {
    yield put(createInitialUserProfileFailure(error));
  }
}

export function* onCreateInitialUserProfileStart() {
  yield takeLatest(UserActionTypes.CREATE_INITIAL_USERPROFILE_START, createInitialUserProfile);
}

export function* createInitialCompany({ payload: { currentUser, companyAttributes } }) {
  try {
    const newCompanyId = yield createInitialCompanyDocument(currentUser, companyAttributes);
    yield put(createInitialCompanySuccess(newCompanyId));
    yield put(setCurrentCompany(newCompanyId));
    yield put(setCurrentView("GettingStarted"));
  } catch (error) {
    yield put(createInitialCompanyFailure(error));
  }
}

export function* onCreateInitialCompanyStart() {
  yield takeLatest(UserActionTypes.CREATE_INITIAL_COMPANY_START, createInitialCompany);
}

export function* userSagas() {
  yield all([ 
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(isUserAuthenticated),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onCreateInitialUserProfileStart),
    call(onCreateInitialCompanyStart)
  ]);
}