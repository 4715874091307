import React from 'react';
import { Container, Header, Segment, Button, Message, Loader, Divider, Feed, Placeholder } from 'semantic-ui-react';
import moment from 'moment';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectModelsErrors, selectModelsLoading, selectCurrentModels } from '../../redux/models/models.selectors';
import { getModelsStart } from '../../redux/models/models.actions';

import ModelReviewEvent from '../ModelReviewEvent/ModelReviewEvent.component';

class ModelsList extends React.Component {

  componentDidMount() {
    const { getModelsStart } = this.props;
    getModelsStart();
  }

  getSortedEventIds = (scenarioEvents) => {
    // Sort events by date modified
    let sortedEventIds = Object.keys(scenarioEvents)
    .sort((a, b) => {
      return moment(scenarioEvents[b].eventMoment).valueOf() - moment(scenarioEvents[a].eventMoment).valueOf();
    });
    return sortedEventIds;
  }

  displayEvents = () => {
    const { currentModels } = this.props;

    // Display sorted events by date modified
    const sortedEventIds = this.getSortedEventIds(currentModels);
    return (
      sortedEventIds.map((model_key, model_i) => (
        <React.Fragment key={`modelreview-${model_key}`}>
          {model_i > 0 ? <Divider /> : null}
          <ModelReviewEvent {...currentModels[model_key]} />
        </React.Fragment>
      ))
    );
  }

  renderModelList = () => {
    const { currentModels, modelsLoading } = this.props;
    if (!currentModels) return null;
    if (!Object.keys(currentModels).length) return null;
    return (
      <Feed size='large'>
      { modelsLoading ? (
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      ) : (
        Object.keys(currentModels).length ? (
          this.displayEvents()
        ) : (
          <p>No scenario events found.</p>
        )
      ) }
      </Feed>
    );
  }

  render() {
    const { modelsErrors, modelsLoading } = this.props;
    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Financial Models</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>All Financial Models</Header.Content>
            </Header>
          </Segment>

          <Segment>
            {modelsLoading ? <Loader active inline='centered' size="small">Loading financial models ...</Loader> : this.renderModelList()}
          </Segment>
          <Segment secondary>
            <Button primary icon="plus" labelPosition="left" content="Add Model Review Event" />
          </Segment>
        </Segment.Group>

        {modelsErrors ? (
          <Message error>
            <Message.Header>{modelsErrors.title}</Message.Header>
            <Message.Content>{modelsErrors.error.message}</Message.Content>
          </Message>
        ) : null}

      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentModels: selectCurrentModels,
  modelsErrors: selectModelsErrors,
  modelsLoading: selectModelsLoading
});

const mapDispatchToProps = dispatch => ({
  // setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getModelsStart: () => dispatch(getModelsStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelsList);