import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  List,
  Label
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './FAQsPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';

const PageHeading = ({ mobile }) => (
  <div className="FAQsPageOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        <Header
          as='h2'
          content={
          <React.Fragment>
            <span style={{ fontFamily: 'Lora, serif' }}>Frequently Asked Questions<span style={{color: "rgb(0, 195, 255)" }}></span></span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        />
        {/* <Header
          as='h2'
          content={
          <React.Fragment>
            Don't see your question listed below?<br/>Drop us a note at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a>
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        /> */}
      </Container>
    </Segment>
  </div>
);

const FAQsPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <PageHeading {...props} />

      <Segment vertical className="white" style={{ marginBottom: '-0.5em', paddingBottom: '0.5em' }}>
        <Container text>

        <Header as='h3'>
          Why does off-balance sheet structuring matter?
        </Header>
        <p>
          Because even though your company might be losing money, you might still be producing financeable cash flows. Without disaggregating and properly structuring your business, you might not be able to lend against it. Furthermore, many lenders are familiar with or even require a bankruptcy-remote off-balance sheet structure.
        </p>

        <Header as='h3'>
          How does my company benefit from the project if it is off-balance sheet?
        </Header>
        <p>
          Depending on the structure, your company (now the HoldCo) can become essentially asset-light. Profits from the project entities can be swept back (a process known as distributions or dividends) to the HoldCo periodically, for example every 3 months. This frees up your HoldCo to raise equity and build out a team without worrying about profitability and maintaining a strong debt service coverage ratio (DSCR) or other required lender metrics.
        </p>

        <Header as='h3'>
          Is an off-balance sheet structure always necessary?
        </Header>
        <p>
          No, this ultimately depends on the lender’s requirements. However, off-balance sheet structuring brings a number of benefits for both you and for the lender. Reach out to <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to learn more.
        </p>

        <Header as='h3'>
          What if my company doesn't have physical hardware?
        </Header>
        <p>
          Our solution works for most capital-intensive business models, including even those without hardware. Please reach out to us at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to see if you’re a good fit.
        </p>

        <Header as='h3'>
          What if I'm also raising an equity round?
        </Header>
        <p>
          That's great! Raising alternative capital alongside a traditional equity financing round will give you more leverage when it comes to negotiation, provides early evidence of scalability and profitability, and allows for equity funds to be used for higher ROI activities rather than financing inventory, for example - which will result in an increased ROE for current and future equity investors.
        </p>

        <Header as='h3'>
          Why not just use Venture Debt?
        </Header>
        <p>
          Venture Debt is generally raised as a percentage of your company’s overall cash reserves given your burn rate, and sometimes includes warrants or restrictions on burn. While it can be useful as a short-term tool to extend your runway - it is not a scalable form of financing, and is generally capped at about 20% of your cash on hand.
        </p>

        <Header as='h3'>
          Is this voodoo magic?
        </Header>
        <p>
          No, this is just an <a href="https://link.medium.com/PweTfxQjK8" target="_blank" rel="noopener noreferrer">application of financial engineering</a> to a space that is otherwise stuck in a traditional mindset. Our structures don’t change the underlying business but through financial engineering it aligns the incentives between customers, suppliers, lenders and shareholders. If you're a hardware company, or if you're seeking a different exit than VC's normally want, you will need to think outside the box.
          Similar methodologies to our approach are common in the film industry, large-scale project finance, real-estate, and other industries. 
        </p>

        </Container>
      </Segment>

      <Footer />
    </AppContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(FAQsPage);