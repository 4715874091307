import AccountsActionTypes from './accounts.types';
import EmployeesActionTypes from '../employees/employees.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentAccounts: {},
  historicalAccountBalances: {},
  recentAccountBalances: {}
}

const accountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeesActionTypes.UPDATE_EMPLOYEE_SUCCESS: {
      const prevAccounts = { ...state.currentAccounts };
      let newAccounts = prevAccounts;
      const accountAssignment = action.payload.employeeData.accountAssignment;
      if (accountAssignment !== "Unassigned" && !prevAccounts[accountAssignment].assignedEmployees.includes(action.payload.employeeId)) {
        newAccounts[accountAssignment].assignedEmployees = [...prevAccounts[accountAssignment].assignedEmployees, action.payload.employeeId];
      }
      return {
        ...state,
        currentAccounts: newAccounts
      }
    }
    case EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS:
    {
      const prevAccounts = { ...state.currentAccounts };
      let newAccounts = prevAccounts;
      const accountAssignment = action.payload.newEmployeeData.accountAssignment;
      if (accountAssignment !== "Unassigned" && !prevAccounts[accountAssignment].assignedEmployees.includes(action.payload.newEmployeeId)) {
        newAccounts[accountAssignment].assignedEmployees = [...prevAccounts[accountAssignment].assignedEmployees, action.payload.newEmployeeId];
      }
      return {
        ...state,
        currentAccounts: newAccounts
      }
    }
    case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS: {
      const prevAccounts = { ...state.currentAccounts };
      let newAccounts = prevAccounts;
      Object.keys(newAccounts).forEach(accountId => {
        if(prevAccounts[accountId].assignedEmployees.includes(action.payload.removedId)) {
          newAccounts[accountId].assignedEmployees = newAccounts[accountId].assignedEmployees.filter(id => id !== action.payload.removedId)
        }
      });
      return {
        ...state,
        currentAccounts: {
          ...newAccounts
        }
      }
    }
    case AccountsActionTypes.DELETE_ACCOUNT_START:
    case AccountsActionTypes.ADD_ACCOUNT_START:
    case AccountsActionTypes.GET_ACCOUNTS_START:
    case AccountsActionTypes.UPDATE_ACCOUNT_START:
    case AccountsActionTypes.GET_HISTORICAL_BALANCES_START:
      return {
        ...state,
        loading: true
      }
    case AccountsActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case AccountsActionTypes.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentAccounts: {
          ...state.currentAccounts,
          ...action.payload
        },
        loading: false,
        error: null
      }
    case AccountsActionTypes.DELETE_ACCOUNT_SUCCESS:
      const prevAccounts = { ...state.currentAccounts };
      let newAccounts = { ...prevAccounts };
      delete newAccounts[action.payload.removedId];
      return {
        ...state,
        currentAccounts: newAccounts,
        loading: false,
        error: null
      }
    case AccountsActionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        currentAccounts: action.payload.currentAccounts,
        loading: false,
        error: null
      }
    case AccountsActionTypes.GET_RECENT_BALANCES_SUCCESS:
      return {
        ...state,
        recentAccountBalances: action.payload.recentBalances,
        loading: false,
        error: null
      }
    case AccountsActionTypes.GET_HISTORICAL_BALANCES_SUCCESS:
      return {
        ...state,
        historicalAccountBalances: action.payload.historicalBalances,
        loading: false,
        error: null
      }
    case AccountsActionTypes.UPDATE_ACCOUNT_FAILURE:
    case AccountsActionTypes.DELETE_ACCOUNT_FAILURE:
    case AccountsActionTypes.ADD_ACCOUNT_FAILURE:
    case AccountsActionTypes.GET_ACCOUNTS_FAILURE:
    case AccountsActionTypes.GET_HISTORICAL_BALANCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong. Refresh and try again, or contact team@perlstreet.com",
          error: action.payload
        }
      }
    default:
      return state;
  }
}

export default accountsReducer;