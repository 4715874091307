import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  List,
  Card
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './HomePage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <div className="heroBackground">
    <div className="heroOverlay">
      <Container text 
        textAlign="center"
        style={{
          paddingTop: mobile ? '2em' : '4em',
          paddingBottom: mobile ? '2.5em' : '5em'
        }}
      >
        <Header
          inverted
          as='h2'
          content={
          <React.Fragment>
            <span style={{ fontFamily: 'Lora, serif' }}><span style={{color: "#40d4e4" }}>Scalable financing </span> 
            for hardware companies.</span>
          </React.Fragment>}
          style={{
            fontSize: mobile ? '1.2em' : '3em',
            fontWeight: '700',
            marginTop: mobile ? '0.5em' : '0.5em',
            marginBottom: '0em'
          }}
        />
        <Header
          inverted
          as='h2'
          content={<React.Fragment>Finance your hardware-driven expansion.</React.Fragment>}
          style={{
            fontSize: mobile ? '1.5em' : '1.7em',
            fontWeight: 'normal',
            marginTop: mobile ? '1em' : '1em',
            marginBottom: '2em'
          }}
        />
        <a href="https://app.perlstreet.com/register">
        <Button 
          // as={Link} to="/signup" 
          color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round"
        >
          Get Started
          <Icon name='right chevron' />
        </Button>
        </a>
      </Container>
    </div>
  </div>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

const HomePage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
  <AppContainer {...props}>

    <HomepageHeading {...props} />
    
    <Segment style={{ padding: '0em 0em 4em 0em' }} vertical className="white">

      <Grid container stackable verticalAlign='middle' style={{ paddingTop: '5em' }}>

        <Grid.Row>
          <Grid.Column floated='left' width={8}>
            <Header as='h3' style={{ fontSize: '2.5em' }}>
              <Header.Content>
                We help <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>hardware</span> companies raise <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>scalable</span> capital for <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>continuous</span> and <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>sustainable</span> expansion.
              </Header.Content>
            </Header>

            <p className="hugeText">Rapidly raise capital at a lower cost by utilizing our specialized financing structures and software platform purpose-built for hardware expansion.</p>
            <p className="hugeText">And when you're ready to expand again, you don't need to start from the beginning anymore. <i>You don't need to sell more of your company's equity to VCs or hit the fundraising road for another 6-9 months.</i></p>
            <p className="hugeText">Just press <span style={{ color: BrandColors.HEX_COLOR_PRIMARY, fontWeight: 'bold' }}>GO</span>, immediately receive more funds, deploy new devices, and <strong>keep scaling your company</strong>.</p>

            {/* <Header.Subheader style={{ marginTop: '1em', fontStyle: 'italic' }}>
              <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span> What's off-balance sheet capital? Good question!<br/>
              Imagine setting up a small company for the sole purpose of financing your project. 
              This allows your main company entity to use equity financing for other purposes, like hiring.
            </Header.Subheader> */}

          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            {/* <Image centered src={"/logos/logo_ps_b_20x.png"} style={{ maxWidth: '150px' }} /> */}
            {/* <Header as="h2">The Modern Hardware Scaling Loop</Header> */}
            <Image src={'media/illustrations/HardwareScalingLoop.png'} centered />
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <Grid.Row>
        <Grid.Column>
          <Divider
            as='h4'
            className='header'
            horizontal
            style={{ margin: '3em 0em', textTransform: 'uppercase' }}
          >
            <p style={{ color: "#4183c4" }}>SOME COMMON USE CASES</p>
          </Divider>
        </Grid.Column>
      </Grid.Row>

      <Container>
        <Card.Group centered>
          <Card>
            <Card.Content>
              <Image src="/media/icons/car-ev.png" floated="right" size="mini" />
              <Card.Header>EV Fleet Expansion</Card.Header>
              <Card.Meta>Inventory Finance</Card.Meta>
              <Card.Description>
                Expand your fleet without having to resort to dilutive financing for new vehicles.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/air-conditioner.png" floated="right" size="mini" />
              <Card.Header>HVAC-as-a-Service</Card.Header>
              <Card.Meta>Hardware-as-a-Service</Card.Meta>
              <Card.Description>
                Deploy new heating and cooling systems for your customers with no up-front costs.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/hydrogen.png" floated="right" size="mini" />
              <Card.Header>Hydrogen Fuel Cells</Card.Header>
              <Card.Meta>Project Financing</Card.Meta>
              <Card.Description>
                Start construction on a massive new fuel cell project with long-term financing.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/solar-panel.png" floated="right" size="mini" />
              <Card.Header>Energy Efficiency Retrofits</Card.Header>
              <Card.Meta>Energy Savings Sharing</Card.Meta>
              <Card.Description>
                Finance energy efficiency retrofits through projected customer energy savings.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/vegetables.png" floated="right" size="mini" />
              <Card.Header>Agriculture Projects</Card.Header>
              <Card.Meta>Receivables Factoring</Card.Meta>
              <Card.Description>
                Sell your customer invoices for immediate cashflows to service your customers.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/wireless-router.png" floated="right" size="mini" />
              <Card.Header>IoT Device Deployments</Card.Header>
              <Card.Meta>Revolving Line of Credit</Card.Meta>
              <Card.Description>
                Expand your new device deployment capacity with a renewable line of credit.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Image src="/media/icons/home.png" floated="right" size="mini" />
              <Card.Header>Prefabricated Buildings</Card.Header>
              <Card.Meta>Securitization Facility</Card.Meta>
              <Card.Description>
                Raise cash for expansion by bundling and selling otherwise illiquid assets.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/recycle.png" floated="right" size="mini" />
              <Card.Header>Circular Economy</Card.Header>
              <Card.Meta>Revenue Share Financing</Card.Meta>
              <Card.Description>
                Financing to purchase and upcycle devices for resale with a revenue share structure.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src="/media/icons/crane.png" floated="right" size="mini" />
              <Card.Header>Mining and Processing</Card.Header>
              <Card.Meta>Equipment Lease Financing</Card.Meta>
              <Card.Description>
                Acquire expensive new equipment for your project with lease finance structures.
              </Card.Description>
            </Card.Content>
          </Card>

        </Card.Group>
        <Segment textAlign="center" basic>
          <p style={{ fontSize: '1.33em' }}>
            <i>... and so much more.</i><br/><br/>
            Not listed above? Absolutely no problem.<br/>
            Our solution works for most capital-intensive businesses.
          </p>
          <Button href="mailto:team@perlstreet.com" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='large' icon="send" labelPosition="right" content="Speak With Our Team" />
        </Segment>
      </Container>


    </Segment>

    <div className="secondaryHeroOverlay">
      <Segment style={{ padding: '5em 0' }} vertical>
        <Container text 
          textAlign="center"
          style={{
            paddingTop: '2em',
            paddingBottom: '2.5em'
          }}
        >
          <Header
            inverted
            as='h2'
            content={
            <React.Fragment>
              <span style={{ fontFamily: 'Lora, serif' }}>Equity financing is <span style={{color: "#40d4e4" }}>no longer your only option</span>.</span>
            </React.Fragment>}
            style={{
              fontSize: '3em',
              fontWeight: '700',
              marginTop: '0.5em',
              marginBottom: '0em'
            }}
          />
          <Header
            inverted
            as='h2'
            content={
            <React.Fragment>
              Perl Street brings innovative data-driven financing structures to the startup world.
            </React.Fragment>}
            style={{
              fontSize: '1.7em',
              fontWeight: 'normal',
              marginTop: '1em',
              marginBottom: '1em'
            }}
          />

          <Button as={Link} to="/how-it-works" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
            style={{
              marginTop: '1em'
            }}
          >
            Learn How it Works
            <Icon name='right chevron' />
          </Button>
        </Container>
      </Segment>
    </div>

    <Segment style={{ padding: '1em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

      <div style={{ margin: 'auto', display: 'block', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', maxWidth: '500px', margin: 'auto' }}>
        <Image centered src="/logos/partners/logo_bpl.png" style={{ height: '100px' }} />
        <Image centered src="/logos/partners/logo_caas.png" style={{ height: '100px' }} />
        <Image centered src="/logos/partners/logo_civlab.png" style={{ height: '100px' }} />
        <Image centered src="/logos/partners/logo_uu.png" style={{ height: '100px' }} />
        </div>
      </div>

      <Grid.Row>
        <Grid.Column>
          <Divider
            as='h4'
            className='header'
            horizontal
            style={{ margin: '1em 0em', textTransform: 'uppercase' }}
          >
            <p style={{ color: "#999999" }}>A FEW OF OUR PARTNERS</p>
          </Divider>
        </Grid.Column>
      </Grid.Row>

      <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

        <Grid.Row reversed="computer">
          <Grid.Column width={9} textAlign="center">
            <Image src={'media/illustrations/DealStructure.png'} centered style={{ marginTop: '3.5em' }} />
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h3' style={{ fontSize: '2.5em' }}>
              <Header.Content>
                Leverage our <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>deal structuring platform</span> for your benefit.
              </Header.Content>
            </Header>

            {/* <p className="hugeText">By properly structuring your deal with Perl Street, you are lowering the perceived risk by lenders. <i>This enables more options for your company:</i></p> */}
            
            <Header as='h3' style={{ fontSize: '1.5em', fontWeight: 'normal' }}>
              <i>By <span style={{ fontWeight: 'bold' }}>properly structuring your deal</span> with Perl Street, you are essentially <span style={{ textDecoration: 'underline' }}>lowering the perceived risk by lenders</span>. <br/><br/>This enables more options for your company:</i>
            </Header>

            <List as='ul' size="huge">
              <List.Item as='li'>Accelerate due diligence</List.Item>
              <List.Item as='li'>Lower your cost of capital</List.Item>
              <List.Item as='li'>Negotiate better deal terms</List.Item>
              <List.Item as='li'>Receive more financing offers</List.Item>
            </List>

            {/* <Header.Subheader style={{ marginTop: '1em', fontStyle: 'italic' }}>
              <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span> What's off-balance sheet capital? Good question!<br/>
              Imagine setting up a small company for the sole purpose of financing your project. 
              This allows your main company entity to use equity financing for other purposes, like hiring.
            </Header.Subheader> */}

          </Grid.Column>
        </Grid.Row>

        <Divider hidden />

        <Grid.Row>
          <Grid.Column width={9} textAlign="center">
            <Image src={'media/illustrations/BorrowingCampaign.png'} centered style={{ marginTop: '3.5em' }} />
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h3' style={{ fontSize: '2.5em' }}>
              <Header.Content>
                Build a <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>Borrowing Campaign</span>.
              </Header.Content>
            </Header>

            {/* <p className="hugeText">By properly structuring your deal with Perl Street, you are lowering the perceived risk by lenders. <i>This enables more options for your company:</i></p> */}
            
            <Header as='h3' style={{ fontSize: '1.5em', fontWeight: 'normal' }}>
              <i>Why would you ever want to just talk to <span style={{ fontWeight: 'bold' }}>a single lender</span>? Our platform is <span style={{ textDecoration: 'underline' }}>designed for founders</span>, not for investors. That's a big deal.<br/><br/>With our platform, you can:</i>
            </Header>

            <List as='ul' size="huge">
              <List.Item as='li'>Invite multiple investors to your secure deal room</List.Item>
              <List.Item as='li'>Display dynamic scenario projections for investors</List.Item>
              <List.Item as='li'>Simulate new deal terms before accepting them</List.Item>
              <List.Item as='li'>Showcase verified unit economics and real-time data</List.Item>
            </List>

            {/* <Header.Subheader style={{ marginTop: '1em', fontStyle: 'italic' }}>
              <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span> What's off-balance sheet capital? Good question!<br/>
              Imagine setting up a small company for the sole purpose of financing your project. 
              This allows your main company entity to use equity financing for other purposes, like hiring.
            </Header.Subheader> */}

          </Grid.Column>
        </Grid.Row>

        {/* <Divider hidden />

        <Grid.Row reversed="computer">
          <Grid.Column width={9}>
            <Image src={'media/illustrations/NewFundraisingProcess.png'} centered />
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h3' style={{ fontSize: '3em' }}>
              How does it work<span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>?</span>
            </Header>
            <List as='ol' size="huge">
              <List.Item as='li'>Build core operational forecasts</List.Item>
              <List.Item as='li'>Create optimal financing structures</List.Item>
              <List.Item as='li'>Close on new funds to scale your assets</List.Item>
              <List.Item as='li'><span>Deploy new units and verify performance  <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span></span></List.Item>
              <List.Item as='li'><span>Unlock additional capital on key milestones <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span></span></List.Item>
            </List>
            <p><span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>*</span> Infinitely repeat steps <span style={{ color: BrandColors.HEX_COLOR_PRIMARY, fontWeight: 'bold' }}>4 & 5</span> as your business grows.</p>
          </Grid.Column>
        </Grid.Row> */}

      </Grid>
    </Segment>

    <Footer />
    
  </AppContainer>
)};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);