import { createSelector } from 'reselect';

const selectModels = state => state.models;

export const selectCurrentModels = createSelector(
  [selectModels],
  (models) => models.currentModels
);

export const selectModelsErrors = createSelector(
  [selectModels],
  (models) => models.error
);

export const selectModelsLoading = createSelector(
  [selectModels],
  (models) => models.loading
);

export const selectSelectedModels = createSelector(
  [selectModels],
  (models) => models.selectedModels
);