const AccountsActionTypes = {
  ADD_ACCOUNT_START: 'ADD_ACCOUNT_START',
  ADD_ACCOUNT_SUCCESS: 'ADD_ACCOUNT_SUCCESS',
  ADD_ACCOUNT_FAILURE: 'ADD_ACCOUNT_FAILURE',

  GET_ACCOUNTS_START: 'GET_ACCOUNTS_START',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE: 'GET_ACCOUNTS_FAILURE',

  UPDATE_ACCOUNT_START: 'UPDATE_ACCOUNT_START',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE: 'UPDATE_ACCOUNT_FAILURE',

  DELETE_ACCOUNT_START: 'DELETE_ACCOUNT_START',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',

  UPDATE_EMPLOYEE_ACCOUNT_START: 'UPDATE_EMPLOYEE_ACCOUNT_START',
  UPDATE_EMPLOYEE_ACCOUNT_SUCCESS: 'UPDATE_EMPLOYEE_ACCOUNT_SUCCESS',
  UPDATE_EMPLOYEE_ACCOUNT_FAILURE: 'UPDATE_EMPLOYEE_ACCOUNT_FAILURE',

  GET_RECENT_BALANCES_START: 'GET_RECENT_BALANCES_START',
  GET_RECENT_BALANCES_SUCCESS: 'GET_RECENT_BALANCES_SUCCESS',
  GET_RECENT_BALANCES_FAILURE: 'GET_RECENT_BALANCES_FAILURE',

  GET_HISTORICAL_BALANCES_START: 'GET_HISTORICAL_BALANCES_START',
  GET_HISTORICAL_BALANCES_SUCCESS: 'GET_HISTORICAL_BALANCES_SUCCESS',
  GET_HISTORICAL_BALANCES_FAILURE: 'GET_HISTORICAL_BALANCES_FAILURE',
};

export default AccountsActionTypes;