import React from 'react';
import { 
  Container, Segment, Header, Divider, Button, Grid, List, Breadcrumb, 
  Dropdown, Icon, Checkbox, Modal, Form, Message 
} from 'semantic-ui-react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { selectCurrentEmployees, selectEmployeesErrors, selectEmployeesLoading } from '../../redux/employees/employees.selectors';
import { deleteEmployeeStart, updateEmployeeStart, updateEmployeeFailure } from '../../redux/employees/employees.actions';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.component';
import TextLinkButton from '../text-link-button/TextLinkButton.component';
import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';

class EmployeeEdit extends React.Component {
  state = {
    confirmDeleteOpen: false,

    employeeName: '',
    phoneNumber: '',
    email: '',
    shiftAssignment: ''
  }

  componentDidMount() {
    const { view, currentEmployees, getShiftsStart, currentCompany } = this.props;
    const employeeId = view.viewData.employeeId;
    const employee = currentEmployees[employeeId];
    if (employee) {
      this.setState({
        employeeName: employee.employeeName,
        phoneNumber: employee.phoneNumber,
        email: employee.email,
        shiftAssignment: employee.shiftAssignment
      });
    }
    getShiftsStart({ currentCompany });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentCompany, updateEmployeeStart, updateEmployeeFailure, view } = this.props;
    const { employeeName, phoneNumber, email, shiftAssignment } = this.state;
    const employeeId = view.viewData.employeeId;
    
    if ( !employeeName || !phoneNumber || !employeeId ) {
      updateEmployeeFailure({
        code: "custom/failed-update-employee",
        message: "Please fill out all required fields"
      })
      return;
    }

    updateEmployeeStart({ 
      employeeId,
      employeeData: {
        employeeName, 
        phoneNumber, 
        email, 
        shiftAssignment: shiftAssignment || "Unassigned" 
      },
      currentCompany
    });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  showConfirmDelete = () => this.setState({ confirmDeleteOpen: true });
  closeConfirmDelete = () => this.setState({ confirmDeleteOpen: false });

  render() {
    const { 
      setCurrentView, view, currentEmployees, deleteEmployeeStart, currentCompany, employeesLoading, employeesErrors,
      shiftsLoading, currentShifts
    } = this.props;
    const { confirmDeleteOpen, employeeName, phoneNumber, email, shiftAssignment } = this.state;
    const employeeId = view.viewData.employeeId;
    const employee = currentEmployees[employeeId];
    return (
      <Container>

        { employee ? (

        <React.Fragment>

        <Modal size="small" open={confirmDeleteOpen} onClose={this.closeConfirmDelete}>
          <Modal.Header><Header color="red"><Icon name="exclamation triangle" color="red"/>Confirm Delete Employee</Header></Modal.Header>
          <Modal.Content style={{ textAlign: 'center' }}>
            <p>Are you sure you want to delete this employee?</p>
            <Header as="h2"><Header.Subheader>{employee.employeeName}</Header.Subheader></Header>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeConfirmDelete} disabled={!!employeesLoading}>Cancel</Button>
            <Button
              negative
              icon='trash'
              labelPosition='right'
              content='Delete Employee'
              onClick={ () => deleteEmployeeStart({ currentCompany, currentEmployees, employeeId })}
              disabled={!!employeesLoading}
              loading={!!employeesLoading}
            />
          </Modal.Actions>
        </Modal>

        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => setCurrentView("EmployeeList")}>Back</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1">
          <Header.Content>Edit Employee</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>{employee.employeeName}</Header.Content>
              <Header.Subheader><Icon name='exclamation triangle' color="orange" />Flagged risk factors 14 mins ago</Header.Subheader>
            </Header>
          </Segment>

          <Segment>

            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>

                  <Form size="large" onSubmit={this.handleSubmit} loading={!!employeesLoading}>
                    <Header>Employee Information</Header>
                    <Form.Input
                      fluid
                      autoComplete="off"
                      icon='user' 
                      iconPosition='left' 
                      placeholder='Employee Name' 
                      required
                      name="employeeName" 
                      value={employeeName}
                      onChange={this.handleChange} 
                    />
                    <Form.Field>
                      <PhoneInput 
                        required
                        autoComplete="off"
                        defaultCountry="US"
                        name="phoneNumber"
                        value={phoneNumber}
                        placeholder='Cell Phone Number' 
                        onChange={(newValue) => this.setState({ phoneNumber: newValue })} 
                      />
                    </Form.Field>

                    <Form.Input 
                      fluid
                      autoComplete="off"
                      icon='mail' 
                      iconPosition='left' 
                      placeholder='Email Address (Optional)' 
                      name="email" 
                      value={email}
                      onChange={this.handleChange} 
                    />

                    <Dropdown
                      search
                      selection
                      fluid
                      loading={!!shiftsLoading}
                      autoComplete="off"
                      name="shiftAssignment"
                      placeholder="Shift Assignment (Optional)"
                      header='Assign Shift'
                      options={
                        !currentShifts ? [{
                          key: "Unassigned",
                          value: "Unassigned",
                          text: "Unassigned"
                        }] : (
                          [ 
                            {
                              key: "Unassigned",
                              value: "Unassigned",
                              text: "Unassigned"
                            },
                            ...Object.keys(currentShifts).map(shiftId => ({
                              key: currentShifts[shiftId].shiftName,
                              value: shiftId,
                              text: currentShifts[shiftId].shiftName
                            }))
                          ]
                        )
                      }
                      value={shiftAssignment || "Unassigned"}
                      onChange={this.handleSelectChange} 
                    />

                    <div style={{ paddingTop: '0.5em' }}>
                      <i>Don't see the shift you're looking for?</i> <TextLinkButton onClick={() => setCurrentView("ShiftCreate")}>Create a new shift instead</TextLinkButton>
                    </div>
                    
                  </Form>

                  {
                    employeesErrors ? (
                      <Message error>
                        <Message.Header>Could not add employee.</Message.Header>
                        { renderCustomizedErrorMessage(employeesErrors.error.code, employeesErrors.error.message) }
                      </Message>
                    ) : null
                  }
                  
                  {/* <Divider hidden />

                  <Header>Notification Settings</Header>

                  <Header as='h4'>
                    <Icon name='user' />
                    <Header.Content>
                      Current manager is {' '}
                      <Dropdown
                        inline
                        header='Select Manager'
                        options={options2}
                        defaultValue={options2[0].value}
                      />
                    </Header.Content>
                  </Header>

                  <Checkbox label='Automatically send surveys' checked /> */}

                </Grid.Column>

                <Grid.Column>

                  <Header>Survey Results History</Header>

                  <List verticalAlign='middle'>

                    <Header as="h2"><Header.Subheader>June 2020</Header.Subheader></Header>

                    <List.Item>
                      <List.Icon name='exclamation triangle' color="orange" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>June 3, 2020</List.Header>
                        <List.Description as='a'>Flagged risk factors at 7:57 AM</List.Description>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name='check circle' color="green" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>June 2, 2020</List.Header>
                        <List.Description as='a'>Cleared for work at 7:55 AM</List.Description>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name='check circle' color="green" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>June 1, 2020</List.Header>
                        <List.Description as='a'>Cleared for work at 7:54 AM</List.Description>
                      </List.Content>
                    </List.Item>

                    <Header as="h2"><Header.Subheader>May 2020</Header.Subheader></Header>

                    <List.Item>
                      <List.Icon name='check circle' color="green" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>May 29, 2020</List.Header>
                        <List.Description as='a'>Cleared for work at 7:55 AM</List.Description>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name='check circle' color="green" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>May 28, 2020</List.Header>
                        <List.Description as='a'>Cleared for work at 7:53 AM</List.Description>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name='check circle' color="green" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>May 27, 2020</List.Header>
                        <List.Description as='a'>Cleared for work at 7:56 AM</List.Description>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name='question circle' verticalAlign='middle' />
                      <List.Content>
                        <List.Header>May 26, 2020</List.Header>
                        <List.Description as='a'>No response received</List.Description>
                      </List.Content>
                    </List.Item>

                    <Divider hidden />

                    <Button content="Show more results" size="small" compact />

                    <Divider hidden />

                  </List>

                  </Grid.Column>

              </Grid.Row>
            </Grid>

          </Segment>
          <Segment secondary>
            <Button color="green" content="Save Changes" labelPosition="left" icon="check" onClick={this.handleSubmit} loading={!!employeesLoading} disabled={!!employeesLoading} />
            <Button color="red" content="Delete Employee" labelPosition="right" icon="trash alternate" onClick={this.showConfirmDelete} disabled={!!employeesLoading} />
          </Segment>
        </Segment.Group>

        </React.Fragment>

        ) : <LoadingSpinner message="Updating your data..." /> }
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  employeesErrors: selectEmployeesErrors,
  currentEmployees: selectCurrentEmployees,
  currentCompany: selectCurrentCompany,
  employeesLoading: selectEmployeesLoading,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  deleteEmployeeStart: ({ currentCompany, currentEmployees, employeeId }) => 
    dispatch(deleteEmployeeStart({ currentCompany, currentEmployees, employeeId })),
  updateEmployeeStart: ({ employeeId, employeeData, currentCompany }) => 
    dispatch(updateEmployeeStart({ employeeId, employeeData, currentCompany })),
  updateEmployeeFailure: (error) => dispatch(updateEmployeeFailure(error)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit);