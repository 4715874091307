const TransactionsActionTypes = {
  ADD_TRANSACTION_START: 'ADD_TRANSACTION_START',
  ADD_TRANSACTION_SUCCESS: 'ADD_TRANSACTION_SUCCESS',
  ADD_TRANSACTION_FAILURE: 'ADD_TRANSACTION_FAILURE',

  GET_TRANSACTIONS_START: 'GET_TRANSACTIONS_START',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',

  UPDATE_TRANSACTION_START: 'UPDATE_TRANSACTION_START',
  UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
  UPDATE_TRANSACTION_FAILURE: 'UPDATE_TRANSACTION_FAILURE',

  DELETE_TRANSACTION_START: 'DELETE_TRANSACTION_START',
  DELETE_TRANSACTION_SUCCESS: 'DELETE_TRANSACTION_SUCCESS',
  DELETE_TRANSACTION_FAILURE: 'DELETE_TRANSACTION_FAILURE',

  UPDATE_EMPLOYEE_TRANSACTION_START: 'UPDATE_EMPLOYEE_TRANSACTION_START',
  UPDATE_EMPLOYEE_TRANSACTION_SUCCESS: 'UPDATE_EMPLOYEE_TRANSACTION_SUCCESS',
  UPDATE_EMPLOYEE_TRANSACTION_FAILURE: 'UPDATE_EMPLOYEE_TRANSACTION_FAILURE',

  GET_DAY_TRANSACTIONS_START: 'GET_DAY_TRANSACTIONS_START',
  GET_DAY_TRANSACTIONS_SUCCESS: 'GET_DAY_TRANSACTIONS_SUCCESS',
  GET_DAY_TRANSACTIONS_FAILURE: 'GET_DAY_TRANSACTIONS_FAILURE',
};

export default TransactionsActionTypes;